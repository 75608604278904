import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import { MAP_FROM_SERVER_COLOR } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types.tsx';
import type { JiraParentFieldInput, JiraParentFieldDefaultValue } from './types';

export const transformDefaultParentValue = (
	defaultValue: JiraParentFieldDefaultValue,
): ParentFieldOption => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const summaryField = defaultValue.fieldsById.edges.find(
		(field) => field.node.__typename === 'JiraSingleLineTextField',
	) as {
		node: {
			text: string;
		};
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const issueTypeField = defaultValue.fieldsById.edges.find(
		(field) => field.node.__typename === 'JiraIssueType',
	) as {
		node: {
			issueType: {
				name: string;
				avatar: {
					xsmall: string;
				};
			};
		};
	};
	const { key, issueId, issueColorField } = defaultValue;
	const summary = summaryField?.node.text;
	const label = `${key} ${summary}`;
	return {
		label,
		value: issueId,
		key,
		color:
			issueColorField && issueColorField.color !== null
				? MAP_FROM_SERVER_COLOR[issueColorField.color]
				: undefined,
		fields: {
			summary,
			issuetype: {
				name: issueTypeField?.node.issueType.name,
				iconUrl: issueTypeField?.node.issueType.avatar.xsmall,
			},
		},
	};
};

export const validateParentField = (
	data?: ParentFieldOption,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!data) {
			return REQUIRED;
		}
	}
	return undefined;
};

export const mutateParentField = ({ value }: ParentFieldOption): JiraParentFieldInput => ({
	parentField: {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issueId: value as string,
	},
});
