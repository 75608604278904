import type { MediaFile } from '@atlaskit/media-picker';
import type { UserOption } from '@atlassian/jira-base-user-picker';
import { getFileIdsFromFiles } from '@atlassian/jira-issue-create-commons';
import {
	mapUserOptionsToIds,
	mapUserOptionsToAccountIds,
} from '@atlassian/jira-issue-create-commons/src/common/ui/fields/base-fields/user-picker-field/utils';
import type { UserValue } from '@atlassian/jira-issue-field-assignee';
import {
	type SelectedOption,
	transformToServerValue,
} from '@atlassian/jira-issue-field-cascading-select';
import {
	type Options as CheckboxOptions,
	mapOptionsToData as mapCheckboxSelectOptionsToData,
} from '@atlassian/jira-issue-field-checkbox-select';
import type { TeamFieldType } from '@atlassian/jira-issue-field-custom-team';
import type { UserPickerOption } from '@atlassian/jira-issue-field-group-picker';
import {
	mapLabelOptionsToData,
	type Option as LabelsOption,
} from '@atlassian/jira-issue-field-labels';
import {
	type Option as PriorityOption,
	mapOptionToData as mapPriorityOptionToData,
} from '@atlassian/jira-issue-field-priority';
import {
	type Option as RadioOption,
	mapOptionToData as mapRadioSelectOptionToData,
} from '@atlassian/jira-issue-field-radio-select';
import {
	type SelectOption,
	mapSelectOptionsToData,
	mapMultiSelectOptionsToData,
} from '@atlassian/jira-issue-field-select';
import { convertTextToAdf } from '@atlassian/jira-issue-field-textarea';
import type { ADF } from '@atlassian/jira-rich-content';
import type { FieldWriter } from '../types';
import type {
	JiraMultipleSelectFieldInputWritable,
	JiraSelectedOptionInput,
	JiraStringFieldInputWritable,
	JiraLabelsFieldInputWritable,
	JiraPriorityInput,
	JiraUserFieldInput,
	JiraADFFieldInput,
	JiraGroupInput,
	JiraUserInput,
} from './types';

const toMultipleSelectField = (id: string, ids: string[]): JiraMultipleSelectFieldInputWritable => {
	const toOptionInput = (optionId: string): JiraSelectedOptionInput => ({ id: optionId });
	return {
		fieldId: id,
		options: ids.map(toOptionInput),
	};
};

const toStringField = (id: string, value: string): JiraStringFieldInputWritable => ({
	fieldId: id,
	value,
});

export const toLabelsField = (id: string, labels: string[]): JiraLabelsFieldInputWritable => ({
	fieldId: id,
	values: {
		values: labels,
	},
});

const toPriorityField = (priorityId: string): JiraPriorityInput => ({
	priorityId,
});

export const toUserField = (id: string, accountId: string): JiraUserFieldInput => ({
	fieldId: id,
	user: {
		accountId,
	},
});

const toADFField = (id: string, adf: ADF): JiraADFFieldInput => {
	const { version } = adf;
	return {
		fieldId: id,
		adf: {
			version,
			jsonValue: adf,
		},
	};
};

const toGroupInput = (pickerOption: UserPickerOption): JiraGroupInput => ({
	groupName: pickerOption.name,
});

export const textFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, value));
};

export const selectFieldWriter: FieldWriter<SelectOption | null> = (id, value, fields) => {
	if (value) {
		fields.stringFields.push(toStringField(id, String(mapSelectOptionsToData(value).id)));
	}
};

export const checkboxSelectFieldWriter: FieldWriter<CheckboxOptions> = (id, value, fields) => {
	fields.multipleSelectFields.push(
		toMultipleSelectField(
			id,
			mapCheckboxSelectOptionsToData(value).map((o) => o.id),
		),
	);
};

export const textAreaFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.adfFields.push(toADFField(id, convertTextToAdf(value)));
};

export const dateFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, value));
};

export const labelsFieldWriter: FieldWriter<LabelsOption[]> = (id, value, fields) => {
	fields.labels.push(toLabelsField(id, mapLabelOptionsToData(value)));
};

export const priorityFieldWriter: FieldWriter<PriorityOption> = (_id, value, fields) => {
	// eslint-disable-next-line no-param-reassign
	fields.priority = toPriorityField(mapPriorityOptionToData(value).id);
};

export const numberFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, value));
};

export const dateTimeFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, value));
};

export const urlFieldWriter: FieldWriter<string> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, value));
};

export const multiUserPickerFieldWriter: FieldWriter<UserOption[]> = (id, value, fields) => {
	const accountIds = mapUserOptionsToAccountIds(value);
	fields.multipleSelectUserPickerFields.push({
		fieldId: id,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		users: accountIds as JiraUserInput[],
	});
};

export const cascadingSelectFieldWriter: FieldWriter<SelectedOption> = (id, value, fields) => {
	const option = transformToServerValue(value);
	if (option !== null) {
		fields.stringFields.push(toStringField(id, option.id));
		if (option.child) {
			fields.stringFields.push(toStringField(`${id}:1`, option.child.id));
		}
	}
};

export const singleUserPickerFieldWriter: FieldWriter<UserOption | null | undefined> = (
	id,
	value,
	fields,
) => {
	if (!value) {
		return;
	}

	const result = mapUserOptionsToIds(value);
	if (!Array.isArray(result)) {
		fields.users.push(toUserField(id, result.id));
	}
};

export const radioSelectFieldWriter: FieldWriter<RadioOption> = (id, value, fields) => {
	fields.stringFields.push(toStringField(id, mapRadioSelectOptionToData(value).id));
};

export const multiSelectFieldWriter: FieldWriter<SelectOption[]> = (id, value, fields) => {
	fields.multipleSelectFields.push(
		toMultipleSelectField(
			id,
			mapMultiSelectOptionsToData(value).map((o) => String(o.id)),
		),
	);
};

export const attachmentFieldWriter: FieldWriter<MediaFile[]> = (_id, value, fields) => {
	if (Array.isArray(value) && value.length >= 1) {
		const ids = getFileIdsFromFiles(value);
		fields.stringFields.push(toStringField('attachment', ids.join(',')));
	}
};

export const descriptionFieldWriter: FieldWriter<ADF | undefined> = (id, value, fields) => {
	if (!value) {
		return;
	}
	fields.adfFields.push(toADFField(id, value));
};

export const singleGroupPickerFieldWriter: FieldWriter<UserPickerOption> = (id, value, fields) => {
	const group = toGroupInput(value);
	fields.singleGroupPickerFields.push({ fieldId: id, group });
};

export const multipleGroupPickerFieldWriter: FieldWriter<UserPickerOption[]> = (
	id,
	value,
	fields,
) => {
	const groups = value.map(toGroupInput);
	fields.multipleGroupPickerFields.push({ fieldId: id, groups });
};

export const teamFieldWriter: FieldWriter<TeamFieldType> = (id, value, fields) => {
	if (value) {
		fields.stringFields.push(toStringField(id, value.value));
	}
};

export const assigneeFieldWriter: FieldWriter<UserValue> = (id, value, fields) => {
	if (value?.accountId) {
		fields.users.push(toUserField(id, value.accountId));
	}
};
