import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '@atlassian/jira-issue-create-commons/src/common/utils/select/index.tsx';
import { validateCascadingSelectField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import { AsyncCascadingSelectEdit } from '@atlassian/jira-issue-field-cascading-select/src/async';
import type { Props, CascadingSelectFieldConfig, FieldValue } from './types';
import { transformToCascadingSelectOptions, transformDefaultValue } from './utils';

const CascadingSelectField = (props: Props) => {
	const {
		fieldProps: { value, onChange, ...fieldProps },
		allowedValues: cascadingSelectOptions,
		width,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isReadOnly = false,
		isEditable = true,
	} = props;

	const { allowedValues, allowedChildValues } =
		transformToCascadingSelectOptions(cascadingSelectOptions);

	return (
		<FieldContainer width={width}>
			<AsyncCascadingSelectEdit
				{...fieldProps}
				value={value}
				allowedValues={allowedValues}
				allowedChildValues={allowedChildValues}
				// @ts-expect-error - TS2322 - Type '(e: ChangeEvent<HTMLElement>) => boolean' is not assignable to type '(e: SyntheticEvent<HTMLElement, Event>) => boolean'.
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				// @ts-expect-error - TS2322 - Type '(value: SelectedOption) => void' is not assignable to type '(value: SelectedOption, meta: null, analyticsEvent: UIAnalyticsEventInterface) => Promise<void>'.
				onChange={onChange}
				isInvalid={false} // Cascading select field handles invalid differently. TODO we will have to update field behaviour to correctly handle isInvalid.
				autoFocus={autoFocus}
				areOptionsOnSameRow={false}
				isEditable={
					ff('uim-cascading-select-field-support-gic_h1cda')
						? !isReadOnly && isEditable
						: isEditable
				}
			/>
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateCascadingSelectField,
	transformDefaultValue,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<CascadingSelectFieldConfig, SelectedOption>, any>'.
})<CascadingSelectFieldConfig, FieldValue>(CascadingSelectField);
export { mutateCascadingSelectField } from './utils';
