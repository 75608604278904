import type { MediaFile } from '@atlaskit/media-picker';
import { performPostRequest } from '@atlassian/jira-fetch';

export const getMediaCopyProjectUrl = (projectId: string) =>
	`/rest/api/2/attachment/upload/project/${projectId}`;

export const getMediaCopyIssueUrl = (issueId: string): string =>
	`/rest/api/2/attachment/upload/issue/${issueId}`;

/**
 * Media Picker uploads files into its own service.
 *
 * For them to be available to be attached to a newly created issue, they must be copied/uploaded
 * from a special (Media) collection and made available as a temporary attachment given the projectId.
 *
 * This temporary attachment has it's own id which Jira understands, and we eventually pass this to
 * a Gira GQL endpoint.
 *
 * If an issue has already been created, we can upload it directly, given the issueId.
 */
type Props = {
	id: string;
	mediaFile: MediaFile;
	isProjectId: boolean;
	attachmentCustomUploadURL?: string;
};

export const copyToAttachment = async (props: Props): Promise<string> => {
	const { id, mediaFile, isProjectId, attachmentCustomUploadURL } = props;
	const { attachmentId } = await performPostRequest(
		attachmentCustomUploadURL ||
			(isProjectId ? getMediaCopyProjectUrl(id) : getMediaCopyIssueUrl(id)),
		{
			body: JSON.stringify({
				fileId: mediaFile.id,
			}),
		},
	);
	return attachmentId;
};

export const getAttachmentsSize = (files: MediaFile[]): number =>
	files.reduce((attachmentsSize, attachment) => attachmentsSize + attachment.size, 0);
