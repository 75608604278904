import React, { useRef } from 'react';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { DescriptionEdit } from '../../description-edit';
import type { Props, DescriptionFieldConfig, FieldValue } from './types';

export const DescriptionField = (props: Props) => {
	const editorKey = useRef(Date.now());
	const {
		fieldProps: { value, onChange },
		mediaUploadContext,
	} = props;

	return (
		<FieldContainer width="100%">
			<DescriptionEdit
				key={editorKey.current}
				onChange={onChange}
				defaultValue={value}
				mediaContext={{
					// @ts-expect-error - TS2740 - Type '{}' is missing the following properties from type 'ViewContext': clientId, serviceHost, token, tokenLifespanInMs, and 3 more
					viewContext: mediaUploadContext || {},
					uploadContext: mediaUploadContext,
					userAuth: null,
				}}
			/>
		</FieldContainer>
	);
};

export default withFormField({})<DescriptionFieldConfig, FieldValue>(DescriptionField);
