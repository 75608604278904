import { FetchError } from '@atlassian/jira-fetch';

export default class ParentFetchError extends Error {
	// @ts-expect-error - TS2564 - Property 'endpoint' has no initializer and is not definitely assigned in the constructor.
	endpoint: string;

	traceId: string | undefined;

	// @ts-expect-error - TS2564 - Property 'error' has no initializer and is not definitely assigned in the constructor.
	error: Error;

	statusCode: number | undefined;

	constructor(error: Error, endpoint: string) {
		if (error instanceof ParentFetchError) {
			return error;
		}
		super(error.message);
		this.error = error;
		this.message = error.message;
		if (error instanceof FetchError) {
			if (error.traceId != null) {
				this.traceId = error.traceId;
			}
			this.statusCode = error.statusCode;
		}
		this.endpoint = endpoint;
	}
}
