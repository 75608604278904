import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import type { AnalyticsAttributes, AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';

type FireIAErrorAnalytics = {
	error: Error;
	id: string;
	viewType: ViewType;
	attributes?: AnalyticsAttributes;
	event?: AnalyticsEvent;
};

export const fireIAErrorAnalytics = ({
	error,
	id,
	viewType,
	attributes = {},
	event,
}: FireIAErrorAnalytics) => {
	const errorType = getErrorType(error);
	const packageName = `${viewType}jiraUiModifications`;

	fireErrorAnalytics({
		event,
		meta: {
			id,
			packageName,
			teamName: 'unbox',
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
		attributes: {
			errorType,
			...attributes,
		},
	});
};

// fireErrorAnalytics fn without viewType prefix in the event name
export const fireUIMErrorAnalytics = ({
	error,
	id,
	viewType,
	attributes = {},
	event,
}: FireIAErrorAnalytics) => {
	const errorType = getErrorType(error);
	const packageName = 'jiraUiModifications';
	fireErrorAnalytics({
		event,
		meta: {
			id,
			teamName: 'unbox',
			packageName,
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
		attributes: {
			errorType,
			...attributes,
			viewType,
		},
	});
};
