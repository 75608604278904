import type {
	PriorityOption,
	Option,
} from '@atlassian/jira-issue-field-priority/src/ui/edit/types.tsx';
import type { JiraPriorityFieldInput } from './types';

export const transformDefaultValue = ({ id, name, iconUrl }: PriorityOption): Option => ({
	label: name,
	value: id,
	iconUrl,
});

export const mutatePriorityField = ({ value }: Option): JiraPriorityFieldInput => ({
	priority: {
		priorityId: value,
	},
});
