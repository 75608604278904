import { buildParentCandidatesForExistingIssueQuery } from '@atlassian/jira-issue-fetch-services-common';
import type { CloudId } from '@atlassian/jira-shared-types';
import { getClient } from '../../../common/utils/client';
import type { ParentCandidatesResponseForExisting } from '../types';

export const DEFAULT_NUMBER_OF_PARENT_CANDIDATES_FOR_EXISTING_ISSUE_RETRIEVAL_LIMIT = 20;

/**
 * Returns parent candidates for an existing issue
 *
 * @param cloudId - The cloud ID of the instance
 * @param issueKey - The key of the issue to find parents for e.g SCRUM-1000
 * @param searchTerm - A search string for the parent, on either full key match or partial summary match
 * @param excludeDone - Whether to exclude done issues from the list of parent candidate results
 * @returns A list of possible parent issues for the specified issue
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	cloudId: CloudId,
	issueKey: string,
	searchTerm: string,
	excludeDone: boolean,
): Promise<ParentCandidatesResponseForExisting> => {
	const variables = {
		cloudId,
		issueKey,
		searchTerm,
		excludeDone,
		first: DEFAULT_NUMBER_OF_PARENT_CANDIDATES_FOR_EXISTING_ISSUE_RETRIEVAL_LIMIT,
	};

	const response: ParentCandidatesResponseForExisting = await getClient('').query({
		query: buildParentCandidatesForExistingIssueQuery(),
		variables,
	});

	return response;
};
