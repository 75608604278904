import React from 'react';
import type { UserOption } from '@atlassian/jira-base-user-picker';
import { MultiUserPickerField, SingleUserPickerField } from '@atlassian/jira-issue-create-commons';
import { PEOPLE_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { multiUserPickerFieldWriter, singleUserPickerFieldWriter } from '../writers';

type PeopleCustomFieldTypeSchemaConfiguration = {
	isMulti: boolean;
};

const PeopleCustomFieldTypeComponent = (
	props: ProvidedFieldProps<PeopleCustomFieldTypeSchemaConfiguration>,
) => {
	if (props.schemaConfiguration.isMulti) {
		return <MultiUserPickerField {...props} />;
	}

	return <SingleUserPickerField {...props} />;
};

export const PeopleCustomFieldType: FieldTypeConfig<
	UserOption | UserOption[],
	PeopleCustomFieldTypeSchemaConfiguration
> = {
	type: PEOPLE_CF_TYPE,
	Component: PeopleCustomFieldTypeComponent,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: (id, value, fields) =>
		Array.isArray(value)
			? multiUserPickerFieldWriter(id, value, fields)
			: singleUserPickerFieldWriter(id, value, fields),
};
