import { MUTATION_GROUP } from './constants';
import type { JiraNumberFieldInput } from './types';

export const transformAsNumber = (value: string): number | undefined => {
	if (value) {
		return Number(value);
	}
	return undefined;
};

export const mutateNumberField = (value: string, fieldId: string): JiraNumberFieldInput => ({
	fieldId,
	value: Number(value),
	mutationGroup: MUTATION_GROUP,
});

export const transformAsString = (value: number): string | undefined => {
	if (value !== undefined) {
		return String(value);
	}
	return undefined;
};
