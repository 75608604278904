import React from 'react';
import { FieldLoader } from '@atlassian/jira-issue-create-commons/src/common/ui/field-loader/index.tsx';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateCheckboxField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import { AsyncCheckboxSelectEdit } from '@atlassian/jira-issue-field-checkbox-select/src/async';
import { mapDataToOptions } from '@atlassian/jira-issue-field-checkbox-select/src/ui/edit/utils.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { closeMenuOnScroll } from '../../common/utils/select';
import type { Props, CheckboxSelectFieldConfig, FieldValue } from './types';

const CheckboxSelectField = (props: Props) => {
	const {
		autoFocus,
		width,
		fieldProps: { value, onChange, ...fieldProps },
		error,
		allowedValues,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isReadOnly = false,
	} = props;
	const options = mapDataToOptions(allowedValues);
	return (
		<FieldContainer width={width}>
			<Placeholder name="async-checkbox-select-edit" fallback={<FieldLoader />}>
				<AsyncCheckboxSelectEdit
					{...fieldProps}
					options={options}
					value={value}
					onChange={onChange}
					isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
					autoFocus={autoFocus}
					isInvalid={Boolean(error)}
					onCloseMenuOnScroll={onCloseMenuOnScroll}
					isDisabled={isReadOnly}
				/>
			</Placeholder>
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateCheckboxField,
	transformDefaultValue: mapDataToOptions,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<CheckboxSelectFieldConfig, Options>, any>'.
})<CheckboxSelectFieldConfig, FieldValue>(CheckboxSelectField);
export { mutateCheckboxSelectField } from './utils';
