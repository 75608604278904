import React, { useCallback, useMemo, useRef } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import {
	useInlineField,
	FAILED_FIELD_STATE,
	PENDING_FIELD_STATE,
	STORED_FIELD_STATE,
} from '@atlassian/jira-forge-controllers-inline-fields-state';
import {
	measureInitialPageLoadAsyncResourcesTiming,
	withMetrics,
} from '@atlassian/jira-forge-ui-analytics';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants';
import { fireOperationalEditFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	CUSTOM_FIELD_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants';
import { LazyIframe } from '@atlassian/jira-forge-ui-iframe-generic';
import type { ForgeCustomFieldValue, CustomUIBridge } from '@atlassian/jira-forge-ui-types';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import type { CustomFieldInlineEdit as CustomFieldInlineEditType } from '@atlassian/jira-forge-ui/src/ui/components/custom-field/edit';
import Placeholder from '@atlassian/jira-placeholder';
import { Skeleton } from '../../../common/ui/skeleton';
import { StopSubmitPropagation } from '../../../common/ui/submit-stop-propogation-wrapper';
import type { LazyComponentProps } from '../../../types';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

const getModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_END,
		() => import(/* webpackChunkName: "async-forge-ui-custom-field-inline-edit" */ './index'),
	);

const skeletonConfig = [{ width: 100 }];

export const LazyCustomFieldInlineEditRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.CustomFieldInlineEdit), { ssr: false }),
);

export const LazyCustomFieldInlineEdit = ({
	onSubmit,
	onModalRender,
	...restProps
}: LazyComponentProps<typeof CustomFieldInlineEditType>) => {
	const requestsCount = useRef(0);
	const [, { setFieldState }] = useInlineField(restProps.extensionData.fieldId);

	const { extension, extensionData, extensionPayload } = restProps;

	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);
	const extensionDataValue = useMemo(() => {
		const preparedExtensionData =
			extensionData?.issue && extensionData?.project
				? extensionData
				: {
						fieldId: extensionData.fieldId,
						fieldType: extensionData.fieldType,
						fieldName: extensionData.fieldName,
						renderContext: extensionData.renderContext,
					};

		return {
			...preparedExtensionData,
			type: extension.type,
			entryPoint: ENTRY_POINT_EDIT,
			isInline: true,
		};
	}, [extension, extensionData]);

	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: CUSTOM_FIELD_MODULE,
			entryPoint: ENTRY_POINT_EDIT,
			extensionData: extensionDataValue,
			extensionPayload,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, extensionPayload, loadingComponent],
	);

	const createRetryFunction = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(fieldValue: Promise<Record<any, any>>) => async () => {
			try {
				setFieldState({
					fieldId: extensionData.fieldId,
					status: PENDING_FIELD_STATE,
				});
				requestsCount.current += 1;
				const submitValue = await Promise.resolve(fieldValue);
				onSubmit(submitValue);
				requestsCount.current -= 1;

				if (requestsCount.current === 0) {
					setFieldState({
						fieldId: extensionData.fieldId,
						status: STORED_FIELD_STATE,
					});
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setFieldState({
					fieldId: extensionData.fieldId,
					status: FAILED_FIELD_STATE,
				});
				fireOperationalEditFailedEvent('issue-create', {
					error,
					isInline: true,
				});
			}

			return undefined;
		},
		[onSubmit, extensionData, setFieldState],
	);

	const submit = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ data: fieldValue }: { data: Promise<Record<any, any>> }): Promise<undefined> => {
			const retryFunction = createRetryFunction(fieldValue);
			setFieldState({ fieldId: extensionData.fieldId, retryFunction });

			await retryFunction();
			return undefined;
		},
		[createRetryFunction, extensionData.fieldId, setFieldState],
	);

	const bridge: CustomUIBridge = useMemo(
		() => ({
			submit,
		}),
		[submit],
	);

	return (
		<StopSubmitPropagation>
			<Placeholder name="custom-field-inline-edit" fallback={loadingComponent}>
				{isCustomUIExtension(extension, ENTRY_POINT_EDIT) ? (
					<LazyIframe {...restProps} {...extraProps} bridge={bridge} isResizable />
				) : (
					<LazyCustomFieldInlineEditRenderer
						{...restProps}
						{...extraProps}
						onModalRender={onModalRender}
						onSubmit={onSubmit}
						bridge={bridge}
					/>
				)}
			</Placeholder>
		</StopSubmitPropagation>
	);
};

export const AsyncCustomFieldInlineEdit = LazyCustomFieldInlineEdit;
