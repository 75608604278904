import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { FieldLoader } from '@atlassian/jira-issue-create-commons/src/common/ui/field-loader/index.tsx';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { preventFormSubmitOnEnterKeyPress } from '@atlassian/jira-issue-create-commons/src/common/utils/date/index.tsx';
import { validateString } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import { AsyncDateTimeFieldEditView } from '@atlassian/jira-issue-field-date-time/src/ui/edit/async';
import Placeholder from '@atlassian/jira-placeholder';
import messages from './messages';
import type { Props, DateTimeFieldConfig, FieldValue } from './types';

export const DateTimeField = (props: Props) => {
	const {
		autoFocus = false,
		width,
		fieldProps: { value, onChange, isDisabled, ...fieldProps },
		error,
		isReadOnly = false,
		fieldName,
	} = props;
	const { formatMessage } = useIntl();

	const handleChange = (dateTimeValue: string | null) => {
		onChange(dateTimeValue || '');
	};

	return (
		<FieldContainer width={width}>
			<Placeholder name="date-time-field-edit-view" fallback={<FieldLoader />}>
				<AsyncDateTimeFieldEditView
					label={fieldName}
					{...fieldProps}
					isDisabled={isDisabled || isReadOnly}
					autoFocus={autoFocus}
					value={value}
					onChange={handleChange}
					isInvalid={Boolean(error)}
					datePickerProps={{
						selectProps: {
							onKeyDown: preventFormSubmitOnEnterKeyPress,
						},
						placeholder: formatMessage(messages.datePickerPlaceholder),
					}}
					timePickerProps={{
						placeholder: formatMessage(messages.timePickerPlaceholder),
					}}
				/>
			</Placeholder>
		</FieldContainer>
	);
};

export default withFormField({ validator: validateString })<DateTimeFieldConfig, FieldValue>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<DateTimeFieldConfig, FieldValue>, any>'.
	DateTimeField,
);
export { mutateDateTimeField } from './utils';
