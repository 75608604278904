import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState, AppId, AppsErrorsMap } from '../../types';
import type { AppError } from '../../utils/types';

export const setAppErrors =
	({ errors, appId }: { errors: AppError[]; appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const previousState: AppsErrorsMap = getState().appsErrors ?? {};
		setState({
			appsErrors: {
				...previousState,
				[appId]: errors,
			},
		});
	};

export const resetAppErrors =
	({ appId }: { appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const previousState: AppsErrorsMap = getState().appsErrors ?? {};

		setState({
			appsErrors: {
				...previousState,
				[appId]: null,
			},
		});
	};
