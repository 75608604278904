// file to be removed with clean up of `jwm-form-field-configs` feature gate
import {
	CASCADING_SELECT_CF_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DUE_DATE_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	NUMBER_CF_TYPE,
	PRIORITY_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	SELECT_CF_TYPE,
	SUMMARY_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	ATTACHMENT_TYPE,
	DESCRIPTION_TYPE,
	LABELS_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	TEAM_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	ASSIGNEE_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { JiraIssueFieldsInputWritable } from '../types';
import {
	textFieldWriter,
	selectFieldWriter,
	checkboxSelectFieldWriter,
	textAreaFieldWriter,
	dateFieldWriter,
	labelsFieldWriter,
	priorityFieldWriter,
	numberFieldWriter,
	dateTimeFieldWriter,
	urlFieldWriter,
	multiUserPickerFieldWriter,
	cascadingSelectFieldWriter,
	singleUserPickerFieldWriter,
	radioSelectFieldWriter,
	multiSelectFieldWriter,
	attachmentFieldWriter,
	descriptionFieldWriter,
	multipleGroupPickerFieldWriter,
	singleGroupPickerFieldWriter,
	teamFieldWriter,
	assigneeFieldWriter,
} from './utils';

type WritersMap = {
	[key: string]: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		writeTo: (id: string, value: any, fields: JiraIssueFieldsInputWritable) => void;
	};
};

const writersMap: WritersMap = {
	// Quadrant 1
	[SUMMARY_TYPE]: { writeTo: textFieldWriter },
	[DESCRIPTION_TYPE]: { writeTo: descriptionFieldWriter },
	[ATTACHMENT_TYPE]: { writeTo: attachmentFieldWriter },

	// Quadrant 2
	[SELECT_CF_TYPE]: { writeTo: selectFieldWriter }, // 1
	[TEXT_CF_TYPE]: { writeTo: textFieldWriter }, // 2
	[MULTI_CHECKBOXES_CF_TYPE]: { writeTo: checkboxSelectFieldWriter }, // 3
	[TEXT_AREA_CF_TYPE]: { writeTo: textAreaFieldWriter }, // 4
	[DUE_DATE_TYPE]: { writeTo: dateFieldWriter }, // 5
	[LABELS_TYPE]: { writeTo: labelsFieldWriter }, // 6
	[LABELS_CF_TYPE]: { writeTo: labelsFieldWriter },
	[PRIORITY_TYPE]: { writeTo: priorityFieldWriter }, // 7
	[DATE_CF_TYPE]: { writeTo: dateFieldWriter }, // 8
	[NUMBER_CF_TYPE]: { writeTo: numberFieldWriter }, // 9
	[DATETIME_CF_TYPE]: { writeTo: dateTimeFieldWriter }, // 10
	[URL_CF_TYPE]: { writeTo: urlFieldWriter }, // 11
	[MULTI_USER_CF_TYPE]: { writeTo: multiUserPickerFieldWriter }, // 12
	[CASCADING_SELECT_CF_TYPE]: { writeTo: cascadingSelectFieldWriter }, // 13
	[USER_CF_TYPE]: { writeTo: singleUserPickerFieldWriter }, // 14
	[RADIO_BUTTONS_CF_TYPE]: { writeTo: radioSelectFieldWriter }, // 15
	[MULTI_SELECT_CF_TYPE]: { writeTo: multiSelectFieldWriter }, // 16
	[GROUP_CF_TYPE]: { writeTo: singleGroupPickerFieldWriter },
	[MULTI_GROUP_CF_TYPE]: { writeTo: multipleGroupPickerFieldWriter },
	[TEAM_CF_TYPE]: { writeTo: teamFieldWriter },
	[TEAMS_PLATFORM_CF_TYPE]: { writeTo: singleUserPickerFieldWriter },
	[ASSIGNEE_TYPE]: { writeTo: assigneeFieldWriter },
};

export default writersMap;
