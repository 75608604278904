import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	dropzone: {
		id: 'issue-create-commons.attachments-field.dropzone',
		defaultMessage: 'Drop files to attach or',
		description: 'dropzone for drag and drop',
	},
	browse: {
		id: 'issue-create-commons.attachments-field.browse',
		defaultMessage: 'Browse',
		description: 'browse file explorer link',
	},
	missingToken: {
		id: 'issue-create-commons.attachments-field.missing-token',
		defaultMessage: 'Something went wrong',
		description: 'Error message when token is missing',
	},
});

export default messages;
