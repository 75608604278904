import React from 'react';
import { PriorityField } from '@atlassian/jira-issue-create-commons';
import type { Option } from '@atlassian/jira-issue-field-priority';
import { PRIORITY_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { priorityFieldWriter } from '../writers';

const PriorityFieldTypeComponent = (props: ProvidedFieldProps) => <PriorityField {...props} />;

export const PriorityFieldTypeConfig: FieldTypeConfig<Option> = {
	type: PRIORITY_TYPE,
	Component: PriorityFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: priorityFieldWriter,
};
