import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FieldMessage } from '@atlassian/jira-issue-create-commons/src/common/ui/field-message/index.tsx';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '@atlassian/jira-issue-create-commons/src/common/utils/select/index.tsx';
import { validateMultiVersionsField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import type { VersionOption } from '@atlassian/jira-issue-field-versions/src/common/types.tsx';
import VersionsEdit from '@atlassian/jira-issue-field-versions/src/ui/edit/main.tsx';
import messages from './messages';
import type { Props, VersionsFieldConfig, FieldValue } from './types';
import { transformVersionExternalOptionstoVersionOptions } from './utils';

const MultiVersionsField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange, id, isRequired },
		fieldId,
		fieldName,
		description,
		isReadOnly = false,
		allowedValues,
		error,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isMini,
	} = props;

	const { formatMessage } = useIntl();
	const options = transformVersionExternalOptionstoVersionOptions(allowedValues);
	const isDisabled = allowedValues.length === 0;
	const onChangeVersionsEdit = (versionEditValue: VersionOption | VersionOption[] | null) => {
		if (Array.isArray(versionEditValue) || versionEditValue === null) {
			onChange(versionEditValue);
		}
	};
	const getVersionsEdit = () => (
		<VersionsEdit
			options={options}
			value={value}
			isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			isMulti
			onChange={onChangeVersionsEdit}
			isInvalid={Boolean(error)}
			fieldId={fieldId}
			onCloseMenuOnScroll={onCloseMenuOnScroll}
			isDisabled={isReadOnly || isDisabled}
			autoFocus={autoFocus}
			placeholder={isDisabled ? formatMessage(messages.none) : ''}
			inputId={id}
			isRequired={isRequired}
		/>
	);
	return (
		<FieldContainer width={width}>
			{isDisabled ? (
				<Tooltip content={formatMessage(messages.tooltip)}>{getVersionsEdit()}</Tooltip>
			) : (
				getVersionsEdit()
			)}
			{!isDisabled && (
				<FieldMessage
					description={description}
					error={error}
					fieldName={fieldName}
					isMini={isMini}
				/>
			)}
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateMultiVersionsField,
	transformDefaultValue: transformVersionExternalOptionstoVersionOptions,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<VersionsFieldConfig, FieldValue>, any>'.
})<VersionsFieldConfig, FieldValue>(MultiVersionsField);
export { mutateMultiVersionsField } from './utils';
