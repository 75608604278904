import React from 'react';
import { SingleSelectField } from '@atlassian/jira-issue-create-commons';
import type { SelectOption } from '@atlassian/jira-issue-field-select';
import { SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { selectFieldWriter } from '../writers';

const SelectCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<SingleSelectField {...props} defaultValue={undefined} />
);

export const SelectCustomFieldTypeConfig: FieldTypeConfig<SelectOption> = {
	type: SELECT_CF_TYPE,
	Component: SelectCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};
