import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useRouter } from '@atlassian/jira-router';
import { useFieldMeta } from '../../services/field-meta';
import { useGetFormWithFieldDataByFormId } from '../../services/get-form';
import { useFormSubmissionStore } from './store';
import type { FormSubmissionReturn } from './types';
import { getFormValidationData } from './utils';

export const FormSubmissionPreloader = () => {
	const [
		{
			match: {
				params: { formId },
			},
		},
	] = useRouter();

	const result = useGetFormWithFieldDataByFormId(String(formId));

	const [state, { updateStore }] = useFormSubmissionStore();

	useEffect(() => {
		if (!isEqual(result, state)) {
			updateStore(result);
		}
	}, [updateStore, result, state]);

	return null;
};

export const useFormSubmission = (): FormSubmissionReturn => {
	const [{ data: formWithFieldData, loading: getFormLoading, error: getFormError }] =
		useFormSubmissionStore();

	const {
		data: fieldMetaData,
		loading: fieldMetaDataLoading,
		error: fieldMetaDataError,
	} = useFieldMeta(
		formWithFieldData?.projectId,
		formWithFieldData?.issueType?.id,
		String(formWithFieldData?.formId),
	);

	const loading = getFormLoading || fieldMetaDataLoading;
	return {
		data: loading
			? undefined
			: {
					fieldMetaData,
					formWithFieldData,
					formSubmissionValidationData: getFormValidationData(formWithFieldData, fieldMetaData),
				},
		formId: String(formWithFieldData?.formId),
		loading,
		error: getFormError || fieldMetaDataError,
	};
};
