import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	fetchParentCandidatesForNewIssue,
	fetchParentCandidatesForExistingIssue,
	type IssueParentsWithMessage,
} from '@atlassian/jira-issue-parent-services';
import type { IssueParentOptionsWithMessage } from '../../common/types';
import { mapDataToOptions } from '../../common/utils';
import type { FetchParentsPropsNew, FetchParentsPropsExisting } from './types';

export const ERROR_REPORTING_TEAM = 'bento';

export const fetchParentsForNew = async (
	props: FetchParentsPropsNew,
): Promise<IssueParentOptionsWithMessage> => {
	const { searchTerm, selectedProjectId, selectedIssueTypeId, cloudId, excludeDone } = props;
	// This is a gira request
	try {
		const response: IssueParentsWithMessage = await fetchParentCandidatesForNewIssue(
			cloudId,
			selectedProjectId,
			selectedIssueTypeId,
			searchTerm,
			excludeDone,
		);
		return {
			options: mapDataToOptions(response.candidates),
			message: response.message,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'fetchParentsForNew',
				packageName: 'jiraIssueFieldParent',
				teamName: ERROR_REPORTING_TEAM,
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw new Error(error);
	}
};

export const fetchParentsForExisting = async (
	props: FetchParentsPropsExisting,
): Promise<IssueParentOptionsWithMessage> => {
	const { searchTerm, issueKey, cloudId, excludeDone } = props;
	// This is a gira request
	try {
		const response: IssueParentsWithMessage = await fetchParentCandidatesForExistingIssue(
			cloudId,
			issueKey,
			searchTerm,
			excludeDone,
		);
		return {
			options: mapDataToOptions(response.candidates),
			message: response.message,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'fetchParentsForExisting',
				packageName: 'jiraIssueFieldParent',
				teamName: ERROR_REPORTING_TEAM,
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};
