import { doc, p } from '@atlaskit/adf-utils/builders';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import type { ADF } from '@atlassian/jira-rich-content';

export const convertTextToAdf = (text: string): ADF => {
	if (text === '') {
		return getEmptyADF();
	}

	return doc(p(text));
};
