import React from 'react';
import { InputField } from '../../common/ui/fields/base-fields/input-field';
import type {
	Props,
	FieldValue,
	InputFieldConfig,
} from '../../common/ui/fields/base-fields/input-field/types';
import { withFormField } from '../../common/ui/with-form-field';
import { validateString } from '../../common/utils/validators';
import { transformAsString } from './utils';

const NumberField = (props: Props) => {
	const { width = 350, ...rest } = props;

	return <InputField type="number" width={width} {...rest} />;
};

export { transformAsNumber, mutateNumberField } from './utils';

export default withFormField({
	validator: validateString,
	transformDefaultValue: transformAsString,
})<InputFieldConfig, FieldValue>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<InputFieldConfig, string>, any>'.
	NumberField,
);

export { NumberField };
