import React, { useCallback } from 'react';
import { CreatableSelect } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base';
import MenuWithFooter from './menu';
import messages from './messages';
import type { Props, MenuListPropsType } from './types';

const ComponentsSelectEdit = (props: Props) => {
	const {
		value,
		options,
		onChange,
		onFocus,
		onBlur,
		placeholder = '',
		autoFocus = false,
		isInvalid = false,
		isDisabled = false,
		showFooter = false,
		onCloseMenuOnScroll,
		projectKey,
		isDropdownMenuFixedAndLayered,
		canCreateNewItem = false,
		formatNewInlineComponentCreateLabel,
		inputId,
		isRequired,
		...fieldProps
	} = props;

	const { formatMessage } = useIntl();

	const getNewInlineComponentCreateLabel = useCallback(
		(inputValue: string): string =>
			`${inputValue} (${
				formatNewInlineComponentCreateLabel !== undefined
					? formatNewInlineComponentCreateLabel
					: formatMessage(messages.createNewItem)
			})`,
		[formatNewInlineComponentCreateLabel, formatMessage],
	);

	const isValidNewOption = useCallback(
		(query: string) => {
			const fieldInputLabels = options.map((option) => option.label.toLowerCase());
			return (
				canCreateNewItem &&
				query.trim() !== '' &&
				!fieldInputLabels.includes(query.trim().toLowerCase())
			);
		},
		[canCreateNewItem, options],
	);

	const handleInlineComponentCreate = (inputValue: string) => {
		onChange([...(value || []), { label: inputValue, value: null }]);
	};

	return (
		<CreatableSelect
			{...{ inputId, required: isRequired }}
			{...fieldProps}
			value={value}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore - TS2322 - Type 'Options' is not assignable to type 'readonly (Options | GroupBase<Options>)[]
			options={options}
			placeholder={placeholder || formatMessage(messages.placeholder)}
			// @ts-expect-error - TS2322 - Type '(arg1: Options) => void' is not assignable to type '(value: OptionsType<{ label: string; value: string; }>, action: ActionMeta<{ label: string; value: string; }>) => void'.
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			autoFocus={autoFocus}
			closeMenuOnScroll={onCloseMenuOnScroll}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			components={{
				MenuList: (menuListProps: MenuListPropsType) => (
					<MenuWithFooter {...menuListProps} showFooter={showFooter} projectKey={projectKey} />
				),
			}}
			isMulti
			isDisabled={isDisabled}
			isValidNewOption={isValidNewOption}
			formatCreateLabel={getNewInlineComponentCreateLabel}
			onCreateOption={handleInlineComponentCreate}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="components-field-select" // added this for testing purpose, it doesn't apply any style
			classNamePrefix="components-field-select"
		/>
	);
};

export default ComponentsSelectEdit;
