import React from 'react';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateRadioSelectField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import RadioSelectEdit from '@atlassian/jira-issue-field-radio-select/src/ui/edit/index.tsx';
import { mapDataToOptions } from '@atlassian/jira-issue-field-radio-select/src/ui/edit/utils.tsx';
import { closeMenuOnScroll } from '../../common/utils/select';
import type { Props, RadioSelectFieldConfig, FieldValue } from './types';
import { transformDefaultValue } from './utils';

const RadioSelectField = (props: Props) => {
	const {
		autoFocus,
		width,
		fieldProps: { value, onChange, isDisabled = false },
		isReadOnly = false,
		error,
		allowedValues,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		ariaLabelledBy,
	} = props;
	const options = mapDataToOptions(allowedValues);
	return (
		<FieldContainer width={width}>
			<RadioSelectEdit
				options={options}
				isDisabled={isReadOnly || isDisabled}
				value={value}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				onChange={onChange}
				autoFocus={autoFocus}
				isInvalid={Boolean(error)}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				ariaLabelledBy={ariaLabelledBy}
			/>
		</FieldContainer>
	);
};

export default withFormField({ validator: validateRadioSelectField, transformDefaultValue })<
	RadioSelectFieldConfig,
	FieldValue
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<RadioSelectFieldConfig, Option>, any>'.
>(RadioSelectField);
