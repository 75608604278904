// @ts-expect-error - TS2614 - Module '"@atlaskit/user-picker"' has no exported member 'LegacyValue'. Did you mean to use 'import LegacyValue from "@atlaskit/user-picker"' instead?
import type { LegacyValue } from '@atlaskit/user-picker';
import type { UserOption } from '@atlassian/jira-base-user-picker/src/types.tsx';
import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import type { UserValue as AssigneeOption } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import type { SelectedOption as CascadingSelectData } from '@atlassian/jira-issue-field-cascading-select/src/common/types.tsx';
import type { Options as CheckboxData } from '@atlassian/jira-issue-field-checkbox-select/src/ui/edit/types.tsx';
import type { Options as ComponentData } from '@atlassian/jira-issue-field-components-field/src/ui/edit/types.tsx';
import type { TeamFieldType } from '@atlassian/jira-issue-field-custom-team/src/common/types.tsx';
import type { UserPickerOption } from '@atlassian/jira-issue-field-group-picker/src/common/types.tsx';
import type { SelectOption as OrganizationsOption } from '@atlassian/jira-issue-field-organizations/src/common/types.tsx';
import type { Option as PriorityData } from '@atlassian/jira-issue-field-priority/src/ui/edit/types.tsx';
import type { Option as RadioGroupData } from '@atlassian/jira-issue-field-radio-group/src/ui/edit/types.tsx';
import type { Option as RadioData } from '@atlassian/jira-issue-field-radio-select/src/ui/edit/types.tsx';
import type { ResolutionOption } from '@atlassian/jira-issue-field-resolution/src/common/types.tsx';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import type { VersionOption } from '@atlassian/jira-issue-field-versions/src/common/types.tsx';
import type { LabelOption } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import {
	MAX_LABEL_SIZE,
	MAX_STRING_SIZE,
	MAX_ALLOWED_LABELS,
	LABELS_MAX_ALLOWED,
	LABELS_INVALID,
	STRING_INVALID,
	FORGE_LABELS_INVALID,
} from './constants';

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const validateString = (value?: string, isRequired: boolean, type?: string): string | undefined => {
	if (isRequired && !value?.trim?.()) {
		return REQUIRED;
	}

	if (typeof value === 'string' && type === 'text' && value?.length > MAX_STRING_SIZE) {
		return STRING_INVALID;
	}
	return undefined;
};

const validateSelectField = (
	value?: SelectOption | SelectOption[],
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateCascadingSelectField = (
	value?: CascadingSelectData,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateAssigneeField = (
	value: AssigneeOption | null,
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateUserPickerField = (
	value: LegacyValue<UserOption>,
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const validateCheckboxField = (value?: CheckboxData, isRequired: boolean): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const validateComponentField = (value?: ComponentData, isRequired: boolean): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateCustomTeamsField = (
	value?: TeamFieldType | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateGroupPickerField = (
	value: LegacyValue<UserPickerOption>,
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (Array.isArray(value) && !value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateMultiVersionsField = (
	value?: VersionOption[] | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
		if (!value.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateOrganizationsField = (
	data?: OrganizationsOption[],
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!data) {
			return REQUIRED;
		}
		if (Array.isArray(data) && !data.length) {
			return REQUIRED;
		}
	}
	return undefined;
};

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const validatePriorityField = (data?: PriorityData, isRequired: boolean): string | undefined => {
	if (isRequired && !data) {
		return REQUIRED;
	}
	return undefined;
};

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const validateRadioSelectField = (data?: RadioData, isRequired: boolean): string | undefined => {
	if (isRequired && !data) {
		return REQUIRED;
	}
	return undefined;
};

const validateRadioGroupField = (
	data?: RadioGroupData,
	isRequired?: boolean,
): string | undefined => {
	if (isRequired && !data) {
		return REQUIRED;
	}
	return undefined;
};
const validateResolutionField = (
	value?: ResolutionOption,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateSingleVersionField = (
	value?: VersionOption | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
	}
	return undefined;
};

const validateLabelField = (labels: LabelOption[] | undefined, isRequired: boolean) => {
	if (isRequired) {
		if (!labels) {
			return REQUIRED;
		}
		if (Array.isArray(labels) && !labels.length) {
			return REQUIRED;
		}
	}

	if (labels && labels?.length > MAX_ALLOWED_LABELS) {
		return LABELS_MAX_ALLOWED;
	}
	if (
		labels &&
		labels
			.map((label) => label.value.trim())
			.some((value) => value.includes(' ') || value.length > MAX_LABEL_SIZE)
	) {
		return LABELS_INVALID;
	}
	return undefined;
};

const validateForgeLabelField = (labels: LabelOption[] | undefined, isRequired: boolean) => {
	if (isRequired) {
		if (!labels) {
			return REQUIRED;
		}
		if (Array.isArray(labels) && !labels.length) {
			return REQUIRED;
		}
	}

	if (labels && labels?.length > MAX_ALLOWED_LABELS) {
		return LABELS_MAX_ALLOWED;
	}
	if (
		labels &&
		labels.map((label) => label.value.trim()).some((value) => value.length > MAX_LABEL_SIZE)
	) {
		return FORGE_LABELS_INVALID;
	}
	return undefined;
};

export {
	validateString,
	validateSelectField,
	validateCascadingSelectField,
	validateUserPickerField,
	validateCheckboxField,
	validateComponentField,
	validateCustomTeamsField,
	validateGroupPickerField,
	validateMultiVersionsField,
	validateOrganizationsField,
	validatePriorityField,
	validateRadioSelectField,
	validateRadioGroupField,
	validateResolutionField,
	validateSingleVersionField,
	validateAssigneeField,
	validateLabelField,
	validateForgeLabelField,
};
