import React, { useLayoutEffect, useState } from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
import { REQUEST_TYPE } from '@atlassian/jira-issue-create-common-types/src/common/constants/graphql-field-types.tsx';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types.tsx';
import { Changeboarding as ParentChangeboarding } from '@atlassian/jira-issue-field-parent/src/ui/changeboarding/index.tsx';
import { ParentFieldEdit } from '@atlassian/jira-issue-field-parent/src/ui/edit/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled';
import { withFormField } from '../../common/ui/with-form-field';
import { closeMenuOnScroll } from '../../common/utils/select';
import type { Props, ParentFieldConfig } from './types';
import { validateParentField, transformDefaultParentValue } from './utils';

// The width of Parent label in English with default font settings
const parentLabelWidthDefault = 39;

export const ParentField = (props: Props) => {
	const {
		fieldId,
		fieldName,
		fieldProps: { value, onChange },
		width,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isExistingIssue,
		selectedProject,
		selectedIssueType,
		issueKey,
		isReadOnly = false,
	} = props;

	const [leftPosition, setLeftPosition] = useState(parentLabelWidthDefault);

	useLayoutEffect(() => {
		const element: HTMLDivElement | null = document?.querySelector('#parent-field-label') || null;
		setLeftPosition(element?.offsetWidth ?? parentLabelWidthDefault);
	}, [fieldName]);

	let issueTypeId: string | undefined;
	if (selectedIssueType) {
		issueTypeId =
			selectedIssueType.type === REQUEST_TYPE
				? selectedIssueType.issueTypeId
				: selectedIssueType.value;
	}

	/**
	 * When creating a sub-task from the GIC modal, we:
	 * - pre-populate the parent field in "src/packages/issue-navigator/issue-operations/meatball-menu/src/ui/dropdown-menu-group/button-item/index"
	 * - disable the parent field to prevent the user to change it
	 *
	 * This prevents the user to make a mistake or bump into potential edge cases.
	 */
	const isDisabled = props.isSubTaskCreationOpen || isReadOnly;

	return (
		<FieldContainer width={width}>
			{ff('parent-field-changeboarding_4ljdy') && (
				<ParentChangeboarding leftPosition={leftPosition} />
			)}

			<ParentFieldEdit
				fieldId={fieldId}
				value={value}
				onChange={onChange}
				autoFocus={autoFocus}
				isDisabled={isDisabled}
				isExistingIssue={isExistingIssue}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				selectedProjectId={selectedProject ? selectedProject.value : undefined}
				selectedIssueTypeId={issueTypeId}
				fetchSuggestionsOnFocus
				passedInIssueKey={
					ff('render-modern-issue-transition_cxt0z') || isFedRamp() ? issueKey : undefined
				}
				spacing="default"
			/>
		</FieldContainer>
	);
};

export default withFormField({
	transformDefaultValue: transformDefaultParentValue,
	validator: validateParentField,
})<ParentFieldConfig, ParentFieldOption>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<ParentFieldConfig, LegacyValue<Option>>, any>'.
	ParentField,
);
export { mutateParentField } from './utils';
