import React, {
	cloneElement,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
} from 'react';
import { styled } from '@compiled/react';
import { Field } from '@atlaskit/form';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { ForgeField } from '@atlassian/jira-forge-field-base/src/forge/index.tsx';
import { reportForgeFailure } from '@atlassian/jira-forge-field-base/src/forge/report-failure/index.tsx';
import { getExtension } from '@atlassian/jira-forge-field-base/src/forge/utils/index.tsx';
import { isForgeCustomField } from '@atlassian/jira-forge-field-base/src/utils/index.tsx';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import { EnvironmentLozenge } from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import { extractEnvFromFieldDescription } from '@atlassian/jira-forge-ui-utils/src/utils/extract-env-from-field-description';
import {
	ErrorBoundaryBypassInput,
	ErrorBoundaryBypassOutput,
	ErrorBoundaryBypassProvider,
} from '@atlassian/jira-issue-adjustments/src/ui/error-boundary-bypass/index.tsx';
import { FieldBusyIndicator } from '@atlassian/jira-issue-adjustments/src/ui/field-busy-indicator/index.tsx';
import { IssueAdjustmentsErrorHandler } from '@atlassian/jira-issue-adjustments/src/ui/uim-root-async/error-handler/index.tsx';
import type { FieldProps } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { getValueFromEventOrValue } from '@atlassian/jira-issue-create-commons/src/common/utils/form/index.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import { GIC_VIEW_TYPE } from '@atlassian/jira-ui-modifications-view-gic/src/common/constants';
import { FieldValueDecoratorAsync } from '@atlassian/jira-ui-modifications-view-gic/src/ui/field-value-decorator/index.tsx';
import { FieldMessage } from '../field-message';
import { FieldSkeleton } from '../loader';
import type { ComponentProps, Options, ReturnComponentProps } from './types';
import { generateKeyForRequiredFromUIM } from './utils';

const withFormField =
	<Value, DefaultValue>(options: Options<Value, DefaultValue>) =>
	<Config, FieldValue>(
		Component: Flow.AbstractComponent<ComponentProps<Config, FieldValue>>,
	): Flow.AbstractComponent<ReturnComponentProps<Config>> =>
	// @ts-expect-error - TS2322 - Type '(ownProps: ReturnComponentProps<Config>) => JSX.Element | null' is not assignable to type 'AbstractComponent<ReturnComponentProps<Config>, any>'.
	(ownProps: ReturnComponentProps<Config>) => {
		const {
			fieldId,
			fieldName,
			fieldType,
			description: descriptionRaw,
			isRequired = false,
			// @ts-expect-error - TS2339 - Property 'defaultValue' does not exist on type '{ areUiModificationsBusy?: boolean | undefined; } & BaseField & { componentKey?: string | undefined; fieldType?: string | undefined; forge?: { selectedProject: SelectedProject; selectedIssueType: IssueOrRequestType; modules: ForgeModules; } | undefined; fieldHelpTextUrl?: string | undefined; onLayoutDone?: (() => vo...'.
			defaultValue = undefined,
			componentKey,
			forge,
			onLayoutDone,
			fieldHelpTextUrl,
			areUiModificationsBusy,
			defaultValueDontTransform,
			isRequiredFromUIM,
			onLinkClickEvent,
			isMini,
			isCollapsed,
			isFieldResponsive,
			fieldWrapperElement,
			...ownPropsRest
		} = ownProps;
		const { publish } = useGICLifeCycleEvents();
		const {
			transformDefaultValue,
			validator,
			shouldRenderFieldMessage = true,
			shouldHideLabel = false,
		} = options;
		const description = isForgeCustomField(fieldType)
			? extractEnvFromFieldDescription(descriptionRaw).description
			: descriptionRaw;

		const hasSentTransformDefaultValueError = useRef(false);

		let transformedDefaultValue;

		try {
			/**
			 * The transformDefaultValue function is generally used to transform a defaultValue
			 * that is passed from the backend, which is in a different data format to the
			 * frontend data format.
			 *
			 * In some situations, we want to set the defaultValue using data that is already
			 * in the frontend data format instead of the backend data format. In these cases
			 * we want to ignore the transformDefaultValue and just use the defaultValue from props.
			 *
			 * To do this, we are using the defaultValueDontTransform prop to know that we
			 * can safely not run transformDefaultValue.
			 */
			if (defaultValueDontTransform) {
				transformedDefaultValue = defaultValue; // this can be merged into the below on ff cleanup
			} else {
				transformedDefaultValue =
					defaultValue !== undefined && typeof transformDefaultValue === 'function'
						? transformDefaultValue(defaultValue)
						: defaultValue;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (hasSentTransformDefaultValueError.current === false) {
				hasSentTransformDefaultValueError.current = true;
				fireErrorAnalytics({
					meta: {
						id: 'defaultValueTransformation',
						packageName: 'jiraIssueCreateCommons',
						teamName: 'gryffindor',
					},
					error,
					attributes: {
						fieldType,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}

		useLayoutEffect(() => {
			onLayoutDone && onLayoutDone();
		}, [onLayoutDone]);

		const shouldSetWidth = ff('gic-smart-fields-jira-labs_8uwvr')
			? isMini || isFieldResponsive
			: isMini;

		const renderComponent = (fieldProps: FieldProps<FieldValue>, error: undefined | string) => (
			// @ts-expect-error Type 'Omit<ReturnComponentProps<Config>, "fieldId" | "fieldName" | "description" | "isRequired" | "defaultValue" | "areUiModificationsBusy" | "componentKey" | "fieldType" | "forge" | "fieldHelpTextUrl" | "onLayoutDone"> & { ...; }' is not assignable to type 'IntrinsicAttributes & PropsWithoutRef<ComponentProps<Config, FieldValue>> & RefAttributes<any> & { ...; }'.
			<Component
				{...ownPropsRest}
				fieldId={fieldId}
				fieldName={fieldName}
				isRequired={isRequired}
				fieldProps={fieldProps}
				width={shouldSetWidth ? '100%' : undefined}
				isMini={isMini}
				description={description}
				defaultValue={defaultValue}
				error={error}
				key={componentKey}
				ariaLabelledBy={`${fieldId}-field-label`}
			/>
		);

		const renderFieldMessage = useCallback(
			(error?: string | boolean) => {
				if (shouldRenderFieldMessage) {
					return (
						<FieldMessage
							fieldId={fieldId}
							description={description}
							error={error}
							fieldName={fieldName}
							fieldHelpTextUrl={fieldHelpTextUrl}
							onLinkClickEvent={onLinkClickEvent}
							isMini={isMini}
						/>
					);
				}
				return null;
			},
			[
				description,
				fieldHelpTextUrl,
				fieldId,
				fieldName,
				isMini,
				onLinkClickEvent,
				shouldRenderFieldMessage,
			],
		);

		const extension = useMemo(() => {
			if (
				typeof fieldType === 'string' &&
				isForgeCustomField(fieldType) &&
				forge?.modules &&
				forge?.hasFinishedLoading
			) {
				return getExtension(fieldType, forge?.modules);
			}

			return null;
		}, [fieldType, forge?.modules, forge?.hasFinishedLoading]);

		useEffect(() => {
			const shouldReportFailure =
				typeof fieldType === 'string' &&
				isForgeCustomField(fieldType) &&
				forge?.hasFinishedLoading &&
				!extension &&
				forge?.modules &&
				forge?.filteredOutModules;

			if (shouldReportFailure) {
				reportForgeFailure(fieldType, forge?.modules, forge?.filteredOutModules);
			}
		}, [
			fieldType,
			extension,
			forge?.modules,
			forge?.filteredOutModules,
			forge?.hasFinishedLoading,
		]);

		const renderFieldContent = (
			// @ts-expect-error - TS2314 - Generic type 'FieldProps' requires 1 type argument(s).
			fieldProps: FieldProps<FieldValue, HTMLInputElement> | FieldProps<FieldValue>,
			error: undefined | string,
		) => {
			if (ff('gic-smart-fields-jira-labs_8uwvr') && isCollapsed) return null;

			const fieldPropsWithPublish: typeof fieldProps = {
				...fieldProps,
				onChange: (value: FieldValue, ...rest: unknown[]) => {
					fieldProps.onChange(value, ...rest);
					publish(GIC_LIFECYCLE_EVENTS.CREATE_FORM_FIELD_VALUE_CHANGE, {
						fieldId,
						fieldName,
						fieldType,
						value: getValueFromEventOrValue(value),
					});
				},
			};
			return (
				<>
					{fieldType !== undefined && isForgeCustomField(fieldType) ? (
						<ForgeField
							fieldId={fieldId}
							fieldName={fieldName}
							fieldType={fieldType}
							fieldProps={fieldPropsWithPublish}
							error={error}
							selectedProject={forge?.selectedProject}
							selectedIssueType={forge?.selectedIssueType}
							extension={extension}
							renderFieldMessage={renderFieldMessage}
							renderDefaultComponent={renderComponent}
						/>
					) : (
						<>
							{fieldWrapperElement ? (
								cloneElement(fieldWrapperElement, {}, [
									renderComponent(fieldPropsWithPublish, error),
									renderFieldMessage(error),
								])
							) : (
								<>
									{renderComponent(fieldPropsWithPublish, error)}
									{renderFieldMessage(error)}
								</>
							)}
						</>
					)}
				</>
			);
		};

		const renderFieldLabel = useCallback(() => {
			if (ff('gic-smart-fields-jira-labs_8uwvr') && isCollapsed) return ''; // TODO Merge this condition with the one below when cleaning gic-smart-fields-jira-labs_8uwvr https://hello.jira.atlassian.cloud/browse/DEE-3790
			if (shouldHideLabel) return ''; // one off case only expected in case of worklog where we want to show the label inside the component code

			if (isForgeCustomField(fieldType)) {
				const { environment } = extractEnvFromFieldDescription(descriptionRaw);
				return (
					<ForgeLabel>
						{fieldName} <EnvironmentLozenge environmentType={environment} />
					</ForgeLabel>
				);
			}

			return fieldName;
		}, [fieldName, fieldType, shouldHideLabel, descriptionRaw, isCollapsed]);

		if (isForgeCustomField(fieldType) && !forge?.hasFinishedLoading) {
			return <FieldSkeleton />;
		}

		if (isForgeCustomField(fieldType) && !extension) {
			return null;
		}

		return (
			<JSErrorBoundary
				id="withFormField"
				packageName="jiraIssueCreateModal"
				fallback="unmount"
				sendToPrivacyUnsafeSplunk
			>
				<Field
					id={`${fieldId}-field`}
					label={renderFieldLabel()}
					elementAfterLabel={
						areUiModificationsBusy ? <FieldBusyIndicator fieldId={fieldId} /> : undefined
					}
					isRequired={isRequired}
					name={fieldId}
					defaultValue={transformedDefaultValue}
					key={generateKeyForRequiredFromUIM({
						fieldId,
						isRequiredFromUIM: isRequiredFromUIM === true,
					})}
					validate={(value, _formState, { dirty }) => {
						try {
							const { type = undefined } = ownPropsRest;

							if (isForgeCustomField(fieldType) && isRequired) {
								// Dirty check is required for the forge custom fields that haven't yet populated the value because it haven't lost a focus
								return dirty && typeof validator === 'function'
									? validator(value, isRequired, type)
									: undefined;
							}

							return typeof validator === 'function'
								? validator(value, isRequired, type)
								: undefined;
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} catch (error: any) {
							fireErrorAnalytics({
								meta: {
									id: 'fieldValidationIssue',
									packageName: 'jiraIssueCreateCommons',
									teamName: 'gryffindor',
								},
								error,
								sendToPrivacyUnsafeSplunk: true,
							});
							return undefined;
						}
					}}
				>
					{({ fieldProps, error }) => (
						<ErrorBoundaryBypassProvider>
							<ErrorBoundaryBypassOutput />
							<JSErrorBoundary
								id="issueAdjustmentsFieldValueDecorator"
								packageName="jiraIssueCreateModal"
								fallback={() => (
									<>
										<IssueAdjustmentsErrorHandler />
										{renderFieldContent(fieldProps, error)}
									</>
								)}
								sendToPrivacyUnsafeSplunk
								attributes={(err: Error) => ({
									errorType: getErrorType(err),
									viewType: GIC_VIEW_TYPE,
									fieldId,
									fieldType,
								})}
							>
								<FieldValueDecoratorAsync
									fieldId={fieldId}
									fieldType={fieldType}
									fieldProps={fieldProps}
								>
									{(interceptedFieldProps) => (
										<ErrorBoundaryBypassInput>
											{renderFieldContent(interceptedFieldProps, error)}
										</ErrorBoundaryBypassInput>
									)}
								</FieldValueDecoratorAsync>
							</JSErrorBoundary>
						</ErrorBoundaryBypassProvider>
					)}
				</Field>
			</JSErrorBoundary>
		);
	};

export { withFormField };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ForgeLabel = styled.span({
	font: token('font.heading.xsmall'),
	display: 'inline-flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});
