import { useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import { getInviteAndAssignPeopleOption } from '@atlassian/jira-invite-and-assign';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	type AnalyticsAttributes,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller';
import { useUserSubscriber } from '@atlassian/jira-user-services';
import { AUTOMATIC_ID } from '../../common/constants';
import type { UserValue, User, UserOption } from '../../common/types';
import {
	getUserOptionFromUser,
	getAutomaticUserOption,
	getUnassignedUser,
	getInvitePeopleOption,
} from '../../common/utils';
import {
	isInviteFromAssignEnabled,
	getInviteFromAssignCohort,
	INVITE_FROM_ASSIGN,
} from '../../feature-flags';
import messages from '../../messages';

const fireInviteFromAssignExposureEvent = (
	createAnalyticsEvent?: ((arg1: AnalyticsAttributes) => UIAnalyticsEvent) | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	suggestionsCount: number,
	isAdmin: boolean,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'exposed';
	const actionSubject = 'feature';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
	});

	const attrs: Attributes = {
		fieldId: 'assignee',
		flagKey: INVITE_FROM_ASSIGN,
		value: getInviteFromAssignCohort(),
		userRole: isAdmin ? 'admin' : 'basic',
		suggestionsCount,
		source: 'assigneeField',
	};

	fireOperationalAnalytics(event, `${actionSubject} ${action}`, attrs);
};

const fireInviteItemRendererEvent = (
	createAnalyticsEvent?: ((arg1: AnalyticsAttributes) => UIAnalyticsEvent) | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	suggestionsCount: number,
	isAdmin: boolean,
	isInviteAndAssignFromBoard = false,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'rendered';
	const actionSubject = 'inviteItem';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
	});

	const attrs: Attributes = {
		fieldId: 'assignee',
		userRole: isAdmin ? 'admin' : 'basic',
		suggestionsCount,
		source: 'assigneeField',
		...(isInviteAndAssignFromBoard && { isInviteAndAssignFromBoard: true }),
	};
	fireUIAnalytics(event, `${actionSubject} ${action}`, attrs);
};

export const useAssigneeOptions = (
	value?: UserValue,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	users: User[],
	intl: IntlShape,
	shouldPreloadAssignToMe: boolean,
	search?: string,
	enablePeopleInvite?: boolean,
	enableAutomaticOption?: boolean,
	suggestedEmailDomain?: string,
	isEligibleForInviteAndAssign?: boolean,
): [UserOption, UserOption[]] => {
	const [exposureEventTriggered, setExposureEventTriggered] = useState<boolean>(false);

	const isAdmin = useIsAdmin();
	const unassigned = getUnassignedUser(intl);
	const automaticOption = getAutomaticUserOption(intl);
	const valueOption: UserOption = getUserOptionFromUser(value || unassigned);
	const [{ data: loggedInUser }] = useUserSubscriber();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const loggedInUserOption = loggedInUser
		? {
				...getUserOptionFromUser(loggedInUser),
				publicName: intl.formatMessage(messages.assignToMe),
			}
		: null;

	const isInvalidSearch = search === undefined || search === '';

	// show unassigned option if value is defined and no search or unassigned display name match the search
	const showUnassigned = isInvalidSearch
		? Boolean(value?.accountId)
		: unassigned.displayName.toLowerCase().startsWith((search || '').toLowerCase());

	let showAutomaticOption = false;

	if (enableAutomaticOption === true) {
		showAutomaticOption = isInvalidSearch
			? Boolean(value?.accountId !== AUTOMATIC_ID)
			: automaticOption.displayName.toLowerCase().startsWith((search || '').toLowerCase());
	}

	const isLoggedInUserCurrentValue =
		loggedInUserOption !== null && valueOption.id === loggedInUserOption.id;

	const isLoggedInUserMatchSearch = users.some(
		(user: User) => loggedInUserOption !== null && user.accountId === loggedInUserOption.id,
	);
	const showAssignToMeOnInit: boolean =
		!isLoggedInUserCurrentValue && (shouldPreloadAssignToMe || isLoggedInUserMatchSearch);
	const showAssignToMe: boolean = isInvalidSearch
		? showAssignToMeOnInit
		: isLoggedInUserMatchSearch;

	const userOptions: UserOption[] = users
		.filter(
			(user: User) =>
				// remove selected user (value) from users list when search is not defined
				(!isInvalidSearch || user.accountId !== valueOption.id) &&
				// remove logged in user (is already added in 2nd position above)
				!(loggedInUserOption !== null && user.accountId === loggedInUserOption.id),
		)
		.map(getUserOptionFromUser);

	const invitePeopleOptions: Array<UserOption> = [];

	if (enablePeopleInvite === true && !isInvalidSearch && userOptions.length < 3) {
		if (!exposureEventTriggered) {
			setExposureEventTriggered(true);
			fireInviteFromAssignExposureEvent(createAnalyticsEvent, userOptions.length, isAdmin);
		}

		if (isEligibleForInviteAndAssign) {
			invitePeopleOptions.push(
				getInviteAndAssignPeopleOption(intl.formatMessage, search, suggestedEmailDomain),
			);
			fireInviteItemRendererEvent(createAnalyticsEvent, userOptions.length, isAdmin, true);
		} else if (isInviteFromAssignEnabled()) {
			fireInviteItemRendererEvent(createAnalyticsEvent, userOptions.length, isAdmin);
			invitePeopleOptions.push(getUserOptionFromUser(getInvitePeopleOption(intl, isAdmin)));
		}
	}

	const options: UserOption[] = [
		...(showUnassigned ? [getUserOptionFromUser(unassigned)] : []),
		...(showAutomaticOption ? [getUserOptionFromUser(automaticOption)] : []),
		...(showAssignToMe && loggedInUserOption !== null ? [loggedInUserOption] : []),
		...userOptions,
		...invitePeopleOptions,
	];

	return [valueOption, options];
};
