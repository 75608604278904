import React, { useState, useMemo, useCallback, useRef, type FocusEvent } from 'react';
import debounce from 'lodash/debounce';
import uuid from 'uuid';
import { useIntl } from '@atlassian/jira-intl';
import type { SessionId } from '@atlassian/jira-issue-analytics';
import {
	filterOptionByLabelAndFilterValues,
	FailedFetchOptionWrapper,
	defaultSelectStyles,
} from '@atlassian/jira-issue-field-select-base';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types';
import type { Option } from '../../common/types';
import { mapLabelsResponseToLabelOptions } from '../../common/utils';
import { useLabelsSuggestions } from '../../services';
import CreatableSelectWithAnalytics from './creatable-select-with-analytics';
import messages from './messages';
import type { Props } from './types';

export const FETCH_DEBOUNCE = 300;

export const LabelsEdit = (props: Props) => {
	const {
		autoCompleteUrl,
		autoFocus,
		onChange,
		onFocus,
		onBlur,
		spacing,
		value,
		options,
		cachedOptions = [],
		formatCreateLabel,
		noOptionsMessage,
		loadingMessage,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		getSuggestions,
		fetchSuggestionsOnMount = true,
		fieldId = LABELS_TYPE,
		isInvalid = false,
		isDisabled = false,
		ariaLabel = '',
		inputId,
		isRequired,
		openMenuOnFocus,
	} = props;

	const [suggestions, setSuggestions] = useState<Option[]>();
	const [defaultSuggestions, setDefaultSuggestions] = useState<Option[]>();
	const [hasLastFetchFailed, setHasLastFetchFailed] = useState<boolean>(false);
	const lastQuery = useRef<string>('');
	const sessionId = useRef<SessionId>(uuid.v4());

	const { formatMessage } = useIntl();
	const [{ isLoadingSuggestions }, { getLabelsSuggestions, setIsLoadingSuggestions }] =
		useLabelsSuggestions({ fieldId, getSuggestions });

	const getFailedFetchMessage = useCallback(
		() => (
			<FailedFetchOptionWrapper>{formatMessage(messages.failedFetch)}</FailedFetchOptionWrapper>
		),
		[formatMessage],
	);

	const fetchSuggestions = useCallback(
		async (query = ''): Promise<void> => {
			try {
				const response: LabelsSuggestionList = await getLabelsSuggestions(
					autoCompleteUrl,
					query,
					sessionId.current,
				);

				setIsLoadingSuggestions(false);

				if (lastQuery.current === query) {
					const results = mapLabelsResponseToLabelOptions(response);

					setSuggestions(results);

					if (query === '') {
						setDefaultSuggestions(results);
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setIsLoadingSuggestions(false);
				setHasLastFetchFailed(true);
				setSuggestions([
					{
						label: getFailedFetchMessage(),
						value: '',
						isDisabled: true,
					},
				]);
			}
		},
		[
			autoCompleteUrl,
			getFailedFetchMessage,
			getLabelsSuggestions,
			lastQuery,
			sessionId,
			setHasLastFetchFailed,
			setIsLoadingSuggestions,
		],
	);

	useMemo(() => {
		if (fetchSuggestionsOnMount) {
			if (!options && !defaultSuggestions) {
				setIsLoadingSuggestions(true);
				fetchSuggestions();
			} else if (options && !defaultSuggestions) {
				setDefaultSuggestions(options);
				setSuggestions(options);
			} else {
				lastQuery.current = '';
				setIsLoadingSuggestions(true);
				fetchSuggestions();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const groupedSuggestions = useMemo(
		() => [
			{
				label: formatMessage(messages.recent),
				options: cachedOptions,
			},
			{
				label: formatMessage(messages.all),
				options: suggestions || [],
			},
		],
		[cachedOptions, formatMessage, suggestions],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, FETCH_DEBOUNCE), []);

	const getCreateLabel = useCallback(
		(inputValue: string): string =>
			`${inputValue} (${
				formatCreateLabel !== undefined ? formatCreateLabel : formatMessage(messages.createNewItem)
			})`,
		[formatCreateLabel, formatMessage],
	);

	const getNoOptionsMessage = useCallback(
		(): string =>
			noOptionsMessage !== undefined ? noOptionsMessage : formatMessage(messages.empty),
		[formatMessage, noOptionsMessage],
	);
	const getLoadingMessage = useCallback(
		(): string => (loadingMessage !== undefined ? loadingMessage : formatMessage(messages.loading)),
		[formatMessage, loadingMessage],
	);

	const filterOption = useCallback(
		(option: Option, query: string): boolean => {
			if (hasLastFetchFailed) {
				// If the fetch has failed, we will show a disabled option to indicate the error to users
				return true;
			}
			return filterOptionByLabelAndFilterValues<Option>(option, query || '');
		},
		[hasLastFetchFailed],
	);

	const onQueryChange = useCallback(
		(query: string): void => {
			debouncedFetchSuggestions.cancel();
			setHasLastFetchFailed(false);
			if (query) {
				lastQuery.current = query;
				setIsLoadingSuggestions(true);
				debouncedFetchSuggestions(query);
			} else {
				lastQuery.current = '';
				setIsLoadingSuggestions(false);
				setSuggestions(defaultSuggestions);
			}
		},
		[debouncedFetchSuggestions, defaultSuggestions, setIsLoadingSuggestions, setSuggestions],
	);

	const onSelectFocus = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			if (!suggestions) {
				setIsLoadingSuggestions(true);
				fetchSuggestions('');
			}
			onFocus && onFocus(e);
		},
		[suggestions, onFocus, setIsLoadingSuggestions, fetchSuggestions],
	);

	const onSelectBlur = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			onBlur && onBlur(e);
		},
		[onBlur],
	);

	const menuPosition = isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined;

	const isLoading = isLoadingSuggestions;

	return (
		<CreatableSelectWithAnalytics
			isMulti
			autoFocus={autoFocus}
			fieldId={fieldId}
			options={groupedSuggestions}
			value={value}
			isDisabled={isDisabled}
			onBlur={onSelectBlur}
			menuPosition={menuPosition}
			styles={defaultSelectStyles}
			onFocus={onSelectFocus}
			noOptionsMessage={getNoOptionsMessage}
			closeMenuOnScroll={onCloseMenuOnScroll}
			loadingMessage={getLoadingMessage}
			placeholder={formatMessage(messages.placeholder)}
			onInputChange={onQueryChange}
			onChange={onChange}
			spacing={spacing}
			isLoading={isLoading}
			filterOption={filterOption}
			formatCreateLabel={getCreateLabel}
			validationState={isInvalid === true ? 'error' : null}
			sessionId={sessionId.current}
			aria-label={ariaLabel}
			inputId={inputId}
			isRequired={isRequired}
			openMenuOnFocus={openMenuOnFocus}
		/>
	);
};

export default LabelsEdit;
