import { layoutSection as layoutSectionFactory } from '../../next-schema/generated/nodeTypes';
import { layoutSectionFull as layoutSectionFullFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name layoutSection_node
 */

/**
 * Need duplicate `type` and `marks` to make both validator and json-schema satisfied
 */

/**
 * @name layoutSection_full_node
 */

/**
 * @stage 0
 * @name layoutSection_with_single_column_node
 */

export const layoutSection = layoutSectionFactory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM() {
    const attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
});
export const layoutSectionFull = layoutSectionFullFactory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM() {
    const attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
});

// it's same as layoutSection
export const layoutSectionWithSingleColumn = layoutSectionFactory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM() {
    const attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
});