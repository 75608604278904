import type { ProjectKey } from '@atlassian/jira-shared-types';
import createEntry from '../../common/utils/api';
import { props } from './constants';

export default createEntry<{
	name: string;
	id: string;
	self: string;
	projectKey: ProjectKey;
}>(props);
