import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useGlobalComponentsProperty } from '@atlassian/jira-compass-common/src/services/use-global-components-property/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FieldMessage } from '@atlassian/jira-issue-create-commons/src/common/ui/field-message/index.tsx';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '@atlassian/jira-issue-create-commons/src/common/utils/select/index.tsx';
import { validateComponentField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import ComponentsSelectEdit from '@atlassian/jira-issue-field-components-field/src/ui/edit/index.tsx';
import { mapDataToOptions } from '@atlassian/jira-issue-field-components-field/src/ui/edit/utils.tsx';
import { StyledFieldSkeleton } from '../../common/ui/styled-common/styled';
import messages from './messages';
import type { Props, ComponentFieldConfig, FieldValue } from './types';

const ComponentsField = (props: Props) => {
	const {
		autoFocus,
		width,
		fieldProps: { value, onChange, id, isRequired, ...fieldProps },
		error,
		allowedValues,
		selectedProject,
		description,
		fieldName,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isReadOnly = false,
		isMini,
	} = props;
	const { formatMessage } = useIntl();
	const isDisabled = allowedValues.length === 0;
	const options = mapDataToOptions(allowedValues);
	const { projectKey } = selectedProject || {};
	const projectKeyStr = projectKey || '';
	const { enabled: areGlobalComponentsEnabled, loading } =
		useGlobalComponentsProperty(projectKeyStr);

	const getComponentSelectEdit = () => {
		if (loading) {
			return <StyledFieldSkeleton />;
		}

		return (
			<ComponentsSelectEdit
				{...fieldProps}
				options={options}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				value={value}
				onChange={onChange}
				autoFocus={autoFocus}
				isInvalid={Boolean(error)}
				showFooter={false}
				projectKey={projectKeyStr}
				placeholder={isDisabled ? formatMessage(messages.none) : ''}
				isDisabled={isDisabled || isReadOnly}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				canCreateNewItem={!areGlobalComponentsEnabled}
				inputId={id}
				isRequired={isRequired}
			/>
		);
	};
	return (
		<FieldContainer width={width}>
			{isDisabled ? (
				<Tooltip content={formatMessage(messages.tooltipWhenDisabled)}>
					{getComponentSelectEdit()}
				</Tooltip>
			) : (
				getComponentSelectEdit()
			)}
			{!isDisabled && (
				<FieldMessage
					description={description}
					error={error}
					fieldName={fieldName}
					isMini={isMini}
				/>
			)}
		</FieldContainer>
	);
};

export default withFormField({
	transformDefaultValue: mapDataToOptions,
	validator: validateComponentField,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<ComponentFieldConfig, Options>, any>'.
})<ComponentFieldConfig, FieldValue>(ComponentsField);

export { mutateComponentsField } from './utils';
