import React from 'react';
import UserPickerEdit from '@atlassian/jira-issue-field-base-user-picker/src/ui/edit/index.tsx';
import { closeMenuOnScroll } from '../../../../utils/select';
import { validateUserPickerField } from '../../../../utils/validators';
import { withFormField } from '../../../with-form-field';
import { FieldContainer } from '../../styled';
import type { Props, UserPickerFieldConfig, FieldValue } from './types';
import { transformDefaultUserValues } from './utils';

const getAutoCompleteUrl = (context: string, fieldId: string, projectKey: string | undefined) =>
	`/rest/api/3/user/recommend?${new URLSearchParams({
		context,
		...(projectKey && { project: projectKey }),
		...(fieldId && { fieldId }),
		query: '',
	})}`;

const UserPickerField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange, id },
		isMulti,
		autoFocus = false,
		fieldId,
		placeholder = ' ',
		isDisabled = false,
		isReadOnly = false,
		onFetchSuggestions,
		isDropdownMenuFixedAndLayered = false,
		context,
		selectedProject,
	} = props;

	return (
		<FieldContainer width={width}>
			<UserPickerEdit
				autoCompleteUrl={getAutoCompleteUrl(context, fieldId, selectedProject?.projectKey)}
				placeholder={placeholder}
				isDisabled={isReadOnly || isDisabled}
				onChange={onChange}
				value={value}
				appearance="normal"
				isMulti={isMulti}
				autoFocus={autoFocus}
				fieldId={fieldId}
				fetchSuggestions={onFetchSuggestions}
				isClearable
				width={width}
				inputId={id}
				onCloseMenuOnScroll={closeMenuOnScroll}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			/>
		</FieldContainer>
	);
};

export { mapUserOptionsToIds, mapUserOptionsToAccountIds } from './utils';

export default withFormField({
	validator: validateUserPickerField,
	transformDefaultValue: transformDefaultUserValues,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<UserPickerFieldConfig, LegacyValue<UserOption>>, any>'.
})<UserPickerFieldConfig, FieldValue>(UserPickerField);
