import {
	NOT_FOUND,
	INTERNAL_SERVER_ERROR,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import FetchError from '@atlassian/jira-fetch/src/utils';
import { fetchParentCandidatesForExistingIssueData } from '@atlassian/jira-issue-fetch-services';
import { PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO } from '@atlassian/jira-issue-fetch-services-common';
import type { IssueKey, CloudId } from '@atlassian/jira-shared-types';
import type { IssueParentsWithMessage } from '../../types';
import ParentFetchError from '../error';
import { transformData } from '../utils';

export const fetchParentCandidatesForExistingIssue = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	searchTerm: string,
	excludeDone: boolean,
): Promise<IssueParentsWithMessage> => {
	const response = fetchParentCandidatesForExistingIssueData(
		cloudId,
		issueKey,
		searchTerm,
		excludeDone,
	);

	const { data, errors } = await response;

	if (!data || errors) {
		const message = errors ? JSON.stringify(errors) : 'No data returned from graphql call';
		throw new ParentFetchError(
			new Error(message),
			PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO,
		);
	}

	if (
		data.jira.parentCandidatesWithInfoForExistingIssue !== undefined &&
		!data.jira?.parentCandidatesWithInfoForExistingIssue
	) {
		// @ts-expect-error - TS2339 - Property 'length' does not exist on type 'never'.
		if (Array.isArray(errors) && errors.length > 0) {
			const errorMessage = JSON.stringify(errors);
			// if `parentCandidatesForExistingIssue` is missing and any errors were in the response
			// assume that an error caused the data to be missing.
			throw new ParentFetchError(
				new FetchError(
					INTERNAL_SERVER_ERROR,
					`Call to gira for parent candidates returned with errors: ${errorMessage}`,
				),
				PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO,
			);
		} else {
			// GraphQL doesn't rely on HTTP status codes to classify errors.
			// But we do have code that knows how to handle 404s (display "Issue not found"
			// screen, etc.), so that's why we're forcing a 404 here.
			throw new ParentFetchError(
				new FetchError(NOT_FOUND, 'Call to gira for parent candidates returned empty response'),
				PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO,
			);
		}
	}

	const candidates =
		data.jira.parentCandidatesWithInfoForExistingIssue !== undefined
			? transformData(data.jira.parentCandidatesWithInfoForExistingIssue.parentCandidates.edges)
			: [];

	return {
		candidates,
		message:
			data.jira.parentCandidatesWithInfoForExistingIssue !== undefined
				? data.jira.parentCandidatesWithInfoForExistingIssue.message
				: undefined,
	};
};
