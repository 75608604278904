import React from 'react';
import { DESCRIPTION_TYPE } from '@atlassian/jira-platform-field-config';
import type { ADF } from '@atlassian/jira-rich-content';
import { DescriptionFieldPlaceholder } from '../../../ui/field-placeholders';
import DescriptionField from '../../../ui/fields/description-field';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { descriptionFieldWriter } from '../writers';

const DescriptionFieldTypeComponent = (props: ProvidedFieldProps) => (
	<DescriptionField
		{...props}
		defaultValue={undefined}
		fieldWrapperElement={null}
		mediaUploadContext={props.mediaUploadContexts.uploadContext}
	/>
);

export const DescriptionFieldTypeConfig: FieldTypeConfig<ADF> = {
	type: DESCRIPTION_TYPE,
	Component: DescriptionFieldTypeComponent,
	placeholder: <DescriptionFieldPlaceholder />,
	writer: descriptionFieldWriter,
};
