import type {
	VersionOption,
	VersionOptionExternal,
} from '@atlassian/jira-issue-field-versions/src/common/types.tsx';
import { transformVersionExternalToVersion } from '@atlassian/jira-issue-field-versions/src/ui/edit/utils.tsx';
import { MUTATION_GROUP } from './constants';
import type { JiraSingleVersionFieldInput } from './types';

export const transformVersionExternalOptionstoVersionOptions = (
	options: VersionOptionExternal[],
): VersionOption[] =>
	options.map(
		(option: VersionOptionExternal): VersionOption => transformVersionExternalToVersion(option),
	);

export const mutateSingleVersionField = (
	{ value }: VersionOption,
	fieldId: string,
): JiraSingleVersionFieldInput => ({
	fieldId,
	mutationGroup: MUTATION_GROUP,
	version: { versionId: value },
});
