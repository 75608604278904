import {
	screenTabsPublicToInternalTransformer,
	screenTabsPublicToInternalValidator,
	getScreenTabsPublicToInternalValidationErrors,
} from '@atlassian/jira-ui-modifications-fields-configuration';
import type { ScreenTabsChangesPublic } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/screen-tabs';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { AppId, IssueAdjustmentsState } from '../../types';
import { toScreenTabsValidationError } from '../../utils/errors';
import { UiModificationsScreenTabsValidationError } from '../../utils/errors/ui-modifications-screentabs-validation-error';

type ProcessIframeScreenTabsChangesPayload = {
	screenTabsChanges: ScreenTabsChangesPublic;
	appId: AppId;
};

/**
 * Update screen tabs store data from changes coming from UI Modifications
 */
export const updateScreenTabs =
	({ screenTabsChanges: screenTabsFromIframe }: ProcessIframeScreenTabsChangesPayload) =>
	({ getState, setState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { screenTabs: internalScreenTabs } = getState();
		if (
			screenTabsFromIframe &&
			internalScreenTabs &&
			screenTabsPublicToInternalValidator(internalScreenTabs, screenTabsFromIframe)
		) {
			setState({
				screenTabs: screenTabsPublicToInternalTransformer(internalScreenTabs, screenTabsFromIframe),
			});
		} else {
			throw new UiModificationsScreenTabsValidationError('Transformation failed', {
				errors: getScreenTabsPublicToInternalValidationErrors(
					internalScreenTabs,
					screenTabsFromIframe,
				).map(toScreenTabsValidationError),
			});
		}
	};
