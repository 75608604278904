import React, { useEffect } from 'react';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { asyncDateTimeFieldEditViewEntrypoint } from './entrypoint';
import type { DateTimeFieldEditViewProps } from './index';

const EMPTY_OBJECT = {} as const;

export const AsyncDateTimeFieldEditView = (props: DateTimeFieldEditViewProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncDateTimeFieldEditViewEntrypoint,
		// use stable reference to avoid re-renders
		EMPTY_OBJECT,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="async-jira-issue-field-date-time-edit-view"
			packageName="jira-issue-field-date-time"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
			fallback={<SkeletonCompiled width="350px" height="40px" />}
			errorFallback="unmount"
		/>
	);
};
