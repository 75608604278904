import React from 'react';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validatePriorityField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import PriorityEdit from '@atlassian/jira-issue-field-priority/src/ui/edit/index.tsx';
import { mapDataToOptions } from '@atlassian/jira-issue-field-priority/src/ui/edit/utils.tsx';
import { closeMenuOnScroll } from '../../common/utils/select';
import type { Props, PriorityFieldConfig, FieldValue } from './types';
import { transformDefaultValue } from './utils';

const PriorityField = (props: Props) => {
	const {
		autoFocus,
		width,
		fieldProps: { value, onChange, id, isRequired },
		error,
		allowedValues,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isReadOnly = false,
		ariaLabelledBy,
	} = props;
	const options = mapDataToOptions(allowedValues);
	return (
		<FieldContainer width={width}>
			<PriorityEdit
				options={options}
				value={value}
				onChange={onChange}
				autoFocus={autoFocus}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				isInvalid={Boolean(error)}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDisabled={isReadOnly}
				inputId={id}
				isRequired={isRequired}
				ariaLabelledBy={ariaLabelledBy}
			/>
		</FieldContainer>
	);
};

export default withFormField({
	validator: validatePriorityField,
	transformDefaultValue,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<PriorityFieldConfig, Option>, any>'.
})<PriorityFieldConfig, FieldValue>(PriorityField);
export { mutatePriorityField } from './utils';
