import React from 'react';
import { NumberField } from '@atlassian/jira-issue-create-commons';
import { STORY_POINT_ESTIMATE_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { TextFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { numberFieldWriter } from '../writers';

const StoryPointEstimateCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<NumberField {...props} />
);

export const StoryPointEstimateCustomFieldTypeConfig: FieldTypeConfig<string> = {
	type: STORY_POINT_ESTIMATE_CF_TYPE,
	Component: StoryPointEstimateCustomFieldTypeComponent,
	placeholder: <TextFieldPlaceholder />,
	writer: numberFieldWriter,
};
