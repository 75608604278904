import { breakout as breakoutFactory } from '../../next-schema/generated/markTypes';
const allowedTypes = ['wide', 'full-width'];

/**
 * @name breakout_mark
 */

export const breakout = breakoutFactory({
  parseDOM: [{
    tag: 'div.fabric-editor-breakout-mark',
    getAttrs(dom) {
      const mode = dom.getAttribute('data-mode');
      return {
        mode: allowedTypes.indexOf(mode || '') === -1 ? 'wide' : mode
      };
    }
  }],
  toDOM(mark) {
    return ['div', {
      class: 'fabric-editor-breakout-mark',
      'data-mode': mark.attrs.mode
    }, 0];
  }
});