import React from 'react';
import { CascadingSelectField } from '@atlassian/jira-issue-create-commons';
import type { SelectedOption } from '@atlassian/jira-issue-field-cascading-select';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { cascadingSelectFieldWriter } from '../writers';

const CascadingSelectFieldTypeComponent = (props: ProvidedFieldProps) => (
	<CascadingSelectField {...props} />
);

export const CascadingSelectFieldTypeConfig: FieldTypeConfig<SelectedOption> = {
	type: CASCADING_SELECT_CF_TYPE,
	Component: CascadingSelectFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: cascadingSelectFieldWriter,
};
