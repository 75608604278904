import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics';
import { getSupportedFieldConfiguration } from '@atlassian/jira-ui-modifications-fields-configuration';
import type {
	FieldInternalMetadata,
	FieldInternalValue,
	FieldPublicValue,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';

type MapFieldFromInternalToPublicPayload = {
	fieldMetadata: FieldInternalMetadata;
	internalFieldValue: unknown;
	fieldType: string;
	viewType: ViewType;
};

export const mapFieldValueFromInternalToPublic = ({
	fieldMetadata,
	internalFieldValue,
	fieldType,
	viewType,
}: MapFieldFromInternalToPublicPayload) => {
	if (fieldMetadata === undefined) {
		throw new Error('Attempt to set value for the uninitialized field');
	}

	let value: FieldInternalValue | FieldPublicValue = internalFieldValue;

	try {
		const valueTransformer = getSupportedFieldConfiguration(viewType, fieldType)
			?.internalToPublicTransformers.value;

		if (valueTransformer) {
			/**
			 * We trust that the internal field data is in the correct shape.
			 * Data that is in the incorrect shape will result in error events
			 * being logged for further investigation. In this error case we will
			 * pass `null` as the value to unblock onInit for the apps
			 *
			 * The `fieldRenderer` passed to the `valueTransformer` is used only for
			 * rich text fields and will be `undefined` for most of the executions
			 */
			value = valueTransformer(internalFieldValue, fieldMetadata?.fieldRenderer);
		}
	} catch (error: unknown) {
		value = null;
		fireIAErrorAnalytics({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
			id: 'internalToPublicValueTransformer',
			viewType,
			attributes: {
				fieldType,
			},
		});
	}

	return value;
};
