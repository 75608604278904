import type { Group, UserPickerOption } from '../types';

export const transformGroupToUserOption = (group: Group): UserPickerOption => ({
	id: group.name,
	name: group.name,
	type: 'group',
});

export const transformUserPickerOptionToGroup = (userOption: UserPickerOption): Group => ({
	groupId: userOption.id,
	name: userOption.name,
	type: 'group',
});

export const transformGroupToName = (
	group: Group,
): {
	name: string;
} => ({
	name: group.name,
});

export const transformGroupsToNames = (
	groups: Group[],
): {
	name: string;
}[] => groups.map((group) => transformGroupToName(group));

export const appendTypePropertyToGroupOptions = (groups: Group[]): Group[] =>
	groups.map((group) => ({
		...group,
		type: 'group',
	}));
