import { gqlTagGira } from '@atlassian/jira-apollo-gira';

/**
 * This is used for Form builder
 */
export const FORM_BUILDER_CONFIGURATION_FRAGMENT = gqlTagGira`
 fragment FormBuilderConfigurationFragment on JiraBusinessFormConfiguration {
    id
    formId
    projectId
    title
    description
    bannerColor
    enabled
    isSubmittableByAllLoggedInUsers
    accessLevel
    fields {
      id
      fieldId
      alias
      description @include(if: $isFormsWorkFgEnabled)
      isRequired @include(if: $isFormsWorkFgEnabled)
    }
    issueType {
      issueTypeId
      name
      type
      avatarId
      avatar {
        medium
      }
      hierarchy {
        level
      }
    }
 }
`;

/**
 * This is used for Form directory
 */
export const FORM_CONFIGURATION_FOR_DIRECTORY = gqlTagGira`
  fragment FormConfigurationFragmentForDirectory on JiraBusinessFormConfiguration {
    id
    formId
    title
    description
    bannerColor
    enabled
    updated
    accessLevel
    issueType {
      issueTypeId
      name
      avatarId
      avatar {
        medium
      }
    }
    updateAuthor {
      name
      accountId
    }
    errors
  }
`;

export const MUTATION_ERROR_FRAGMENT = gqlTagGira`
  fragment MutationErrorFragment on MutationError {
    message
    extensions {
      statusCode
      errorType
    }
  }
`;
