import type { PriorityData, PriorityOption, Option, Options } from './types';

export const mapDataToOptions = (data: PriorityData): Options =>
	data.map(({ id, name, iconUrl }) => ({ label: name, value: id, iconUrl }));

export const mapOptionToData = ({ label, value, iconUrl }: Option): PriorityOption => ({
	id: value,
	name: label,
	iconUrl,
});
