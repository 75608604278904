import { gqlTagGira } from '@atlassian/jira-apollo-gira';
import { FORM_BUILDER_CONFIGURATION_FRAGMENT } from '../constants';

/**
 * This is used for Form builder
 */
export const JIRA_BUSINESS_FORM_BY_ID = gqlTagGira`
  query JiraBusinessFormById($formId: ID!, $isFormsWorkFgEnabled: Boolean!) {
    jiraBusinessForm(formId: $formId) {
      ...FormBuilderConfigurationFragment
    }
  }

  ${FORM_BUILDER_CONFIGURATION_FRAGMENT}
`;

/**
 * This is used for Form submission
 */
export const JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_ID = gqlTagGira`
  query JiraBusinessFormWithFieldDataByFormId($formId: ID!, $isFormsWorkFgEnabled: Boolean!) {
    jiraBusinessForm(formId: $formId) {
      formId
      projectId
      issueType {
        name
        issueTypeId
      }
      title
      description
      bannerColor
      enabled
      accessLevel
      fields {
        fieldId
        alias
        description @include(if: $isFormsWorkFgEnabled)
        isRequired @include(if: $isFormsWorkFgEnabled)
        field {
          name
          type
          ... on GenericIssueField {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraIssueFieldConfiguration {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraPriorityField {
            fieldConfig {
              isRequired
            }
          }
        }
      }
    }
  }
`;
