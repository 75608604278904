import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateSingleVersionField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import type { VersionOption } from '@atlassian/jira-issue-field-versions/src/common/types.tsx';
import VersionsEdit from '@atlassian/jira-issue-field-versions/src/ui/edit/main.tsx';
import { transformVersionExternalToVersion } from '@atlassian/jira-issue-field-versions/src/ui/edit/utils.tsx';
import { FieldMessage } from '../../common/ui/field-message';
import { closeMenuOnScroll } from '../../common/utils/select';
import messages from './messages';
import type { Props, VersionsFieldConfig, FieldValue } from './types';
import { transformVersionExternalOptionstoVersionOptions } from './utils';

const SingleVersionField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange },
		fieldId,
		fieldName,
		description,
		allowedValues,
		error,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isMini,
		ariaLabelledBy,
	} = props;

	const { formatMessage } = useIntl();

	const onChangeVersionsEdit = (versionEditValue: VersionOption | VersionOption[] | null) => {
		if (!Array.isArray(versionEditValue)) {
			onChange(versionEditValue);
		}
	};

	const options = transformVersionExternalOptionstoVersionOptions(allowedValues);
	const isDisabled = allowedValues.length === 0;

	const getVersionsEdit = () => (
		<VersionsEdit
			options={options}
			value={value}
			onChange={onChangeVersionsEdit}
			isInvalid={Boolean(error)}
			fieldId={fieldId}
			isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			isMulti={false}
			isDisabled={isDisabled}
			placeholder={isDisabled ? formatMessage(messages.none) : ''}
			autoFocus={autoFocus}
			onCloseMenuOnScroll={onCloseMenuOnScroll}
			ariaLabelledBy={ariaLabelledBy}
		/>
	);

	return (
		<FieldContainer width={width}>
			{isDisabled ? (
				<Tooltip content={formatMessage(messages.tooltip)}>{getVersionsEdit()}</Tooltip>
			) : (
				getVersionsEdit()
			)}
			{!isDisabled && (
				<FieldMessage
					description={description}
					error={error}
					fieldName={fieldName}
					isMini={isMini}
				/>
			)}
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateSingleVersionField,
	transformDefaultValue: transformVersionExternalToVersion,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<VersionsFieldConfig, FieldValue>, any>'.
})<VersionsFieldConfig, FieldValue>(SingleVersionField);
export { mutateSingleVersionField } from './utils';
