import React from 'react';
import type { UserOption } from '@atlassian/jira-base-user-picker';
import { SingleUserPickerField } from '@atlassian/jira-issue-create-commons';
import { USER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig } from '../types';
import { singleUserPickerFieldWriter } from '../writers';

export const UserCustomFieldTypeConfig: FieldTypeConfig<UserOption> = {
	type: USER_CF_TYPE,
	Component: SingleUserPickerField,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: singleUserPickerFieldWriter,
};
