import { fireOperationalFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit';
import type { CustomFieldExtension } from '@atlassian/jira-forge-ui-types';
import { getExtensionIdFromLegacyFieldType, isForgeLegacyFieldType } from '../../utils';

export const reportForgeFailure = (
	fieldType: string,
	modules: CustomFieldExtension[],
	filteredOutModules: CustomFieldExtension[],
) => {
	const isExtensionFilteredOut = filteredOutModules.some((field: CustomFieldExtension) =>
		isForgeLegacyFieldType(fieldType)
			? field.id === getExtensionIdFromLegacyFieldType(fieldType)
			: field.id === fieldType,
	);

	const isEmptyForgeData = modules.length === 0 && filteredOutModules.length === 0;

	/*
        Not log error:
         * if the extensions is filtered out by display conditions or access narrowing
         * if the forge data is empty (when fetching error there is an excluded error, empty response is returned)
    */
	if (isEmptyForgeData || isExtensionFilteredOut) {
		return;
	}

	fireOperationalFailedEvent('issue-create', {
		error: Error('Forge extension passed to ForgeCustomField component is null or undefined'),
		renderContext: 'issue-create',
	});
};
