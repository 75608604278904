import React from 'react';
import { SingleSelectField } from '@atlassian/jira-issue-create-commons';
import type { SelectOption } from '@atlassian/jira-issue-field-select';
import { CATEGORY_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { selectFieldWriter } from '../writers';

const CategoryFieldTypeComponent = (props: ProvidedFieldProps) => (
	<SingleSelectField {...props} defaultValue={undefined} />
);

export const CategoryFieldTypeConfig: FieldTypeConfig<SelectOption> = {
	type: CATEGORY_TYPE,
	Component: CategoryFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};
