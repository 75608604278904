import mapValues from 'lodash/mapValues';
import { GROUP_CF_TYPE, MULTI_GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { FieldsMeta, FieldMeta, AllowedValue, CascadingAllowableValue } from './types';

export const filterFieldsMeta = (fieldMeta: FieldsMeta): FieldsMeta => {
	// remove specific field property from fieldMeta
	const { issuetype, project, reporter, ...otherFieldMeta } = fieldMeta;
	return otherFieldMeta;
};

export const CASCADING_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect';
const isCascadingField = (fieldMeta: FieldMeta) =>
	fieldMeta.schema?.custom === CASCADING_FIELD_TYPE;

// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type.
const transformCascadingFieldChildValue = ({ id, value }) => ({
	id,
	value,
	__typename: 'JiraCascadingOptions',
});

export const transformCascadingFieldAllowedValue = ({
	id,
	value,
	children = [],
}: AllowedValue): CascadingAllowableValue => ({
	parentOptionValue: {
		id,
		value,
		__typename: 'JiraOption',
	},
	childOptionValues: children.map(transformCascadingFieldChildValue),
});

// https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-groups/#api-rest-api-3-groups-picker-get
const GROUP_PICKER_API_ENDPOINT = '/rest/api/3/groups/picker?query=';
const isGroupField = (fieldMeta: FieldMeta) =>
	fieldMeta.schema?.custom === GROUP_CF_TYPE || fieldMeta.schema?.custom === MULTI_GROUP_CF_TYPE;
const transformGroupField = (fieldMeta: FieldMeta) => ({
	...fieldMeta,
	autoCompleteUrl: GROUP_PICKER_API_ENDPOINT,
});

/**
 * Transform cascading fields and group fields meta data.
 *
 * Note: Transforming cascading fields meta data is a temporary fix to transform the "createmeta" response so
 * it matches the expected "allowedValues" shape that's expected in the CascadingSelectField component.
 * This will be permanently fixed once we leverage allowable values from availableFields.
 */
export const transformFieldsMeta = (fieldsMeta: FieldsMeta) =>
	mapValues(fieldsMeta, (field) => {
		// An autocomplete URL is not included in field meta data for Single or Multi group picker fields.
		// For now, manually include the autocomplete URL for these fields.
		if (isGroupField(field)) {
			return transformGroupField(field);
		}

		if (!isCascadingField(field)) {
			return field;
		}

		const { allowedValues = [], ...rest } = field;

		return {
			allowedValues: allowedValues.map(transformCascadingFieldAllowedValue),
			...rest,
		};
	});
