import { useState, useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	MediaUploadPermissionResponse,
	UseUploadPermissions,
	Status,
	UploadContext,
} from './types';
import { getMediaUploadPermissionUrl } from './utils';

const QUERY_ID = 'formSubmissionMediaPermissions';

export const useUploadPermissions: UseUploadPermissions = (isDescriptionField) => {
	const [uploadPermissionStatus, setUploadPermissionStatus] = useState<Status>('NOT_STARTED');

	const [uploadPermissions, setUploadPermissions] = useState<UploadContext | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetchUploadPermissions = useCallback(
		async (projectId: string, formId: string): Promise<void> => {
			setUploadPermissionStatus('LOADING');

			try {
				const url = getMediaUploadPermissionUrl(formId, Boolean(isDescriptionField));

				const uploadResponse: MediaUploadPermissionResponse = await performGetRequest(url);
				setUploadPermissions({
					clientId: uploadResponse.clientId,
					serviceHost: uploadResponse.endpointUrl,
					token: uploadResponse.token,
					tokenLifespanInMs: uploadResponse.tokenDurationInMins * 60 * 1000,
					collection: uploadResponse.targetCollection,
					tokenIssueTimestamp: Date.now() - 10000,
				});

				setUploadPermissionStatus('COMPLETED');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setUploadPermissionStatus('COMPLETED');

				if (err.statusCode === 403) {
					// user does not have media permission
					fireTrackAnalytics(createAnalyticsEvent({}), `${QUERY_ID} forbidden`);
				} else {
					fireErrorAnalytics({
						meta: {
							id: QUERY_ID,
							packageName: 'jiraBusinessForm',
							teamName: 'jira-werewolves',
						},
						attributes: {
							statusCode: err.statusCode,
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
		},
		[createAnalyticsEvent, isDescriptionField],
	);
	return {
		uploadPermissions,
		fetchUploadPermissions,
		uploadPermissionStatus,
	};
};
