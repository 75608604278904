import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';

export const FORM_APP_HEADER_HEIGHT = 250;

export const URL_PARAMS = {
	FROM: 'from',
	DIRECTORY: 'directory',
};

export const PACKAGE_NAME = 'jiraBusinessForm';

export const VIEW_FORM_DIRECTORY_EXPERIENCE: ExperienceDetails = {
	experience: 'viewFormDirectory',
	experienceId: 'viewDirectory',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const DELETE_FORM_EXPERIENCE: ExperienceDetails = {
	experience: 'deleteForm',
	experienceId: 'deleteForm',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const VIEW_FORM_SUBMISSION_EXPERIENCE: ExperienceDetails = {
	experience: 'formSubmission',
	experienceId: 'main',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const SUBMIT_FORM_EXPERIENCE: ExperienceDetails = {
	experience: 'CreateJiraBusinessIssueForm',
	experienceId: 'mutation',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const VIEW_FORM_CREATE_EXPERIENCE: ExperienceDetails = {
	experience: 'formCreation',
	experienceId: 'main',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const CREATE_FORM_EXPERIENCE: ExperienceDetails = {
	experience: 'CreateJiraBusinessForm',
	experienceId: 'mutation',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const VIEW_FORM_UPDATE_EXPERIENCE: ExperienceDetails = {
	experience: 'formUpdate',
	experienceId: 'main',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const UPDATE_FORM_EXPERIENCE: ExperienceDetails = {
	experience: 'UpdateJiraBusinessForm',
	experienceId: 'mutation',
	packageName: 'jiraBusinessForm',
	teamName: 'jira-werewolves',
};

export const FORM_VALIDATION_ERROR = {
	ATTACHMENT_ERROR: 'ATTACHMENT_VALIDATION_ERROR',
};

export const BUSINESS_PREFIX = '/jira/core';
export const SOFTWARE_TMP_PREFIX = '/jira/software';
export const SOFTWARE_CMP_PREFIX = '/jira/software/c';
