import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { components } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { GroupOption } from '../../../common/types';
import messages from '../../../messages';

export type Props = {
	options: GroupOption[];
	children: ReactNode;
};

const MenuListComponent = (props: Props) => {
	const { options } = props;
	const { formatMessage } = useIntl();

	const footer =
		options.length > 0 && options[0].moreResultsAvailable ? (
			<MenuFooterText>{formatMessage(messages.menuFooter)}</MenuFooterText>
		) : null;
	return (
		// @ts-expect-error - TS2740 - Type '{ children: (Element | ReactNode)[]; options: GroupOption[]; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
		<components.MenuList {...props}>
			<Container>{formatMessage(messages.menuHeader)}</Container>
			{props.children}
			{footer}
		</components.MenuList>
	);
};

export default MenuListComponent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuFooterText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N90),
	font: token('font.body'),
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
});
