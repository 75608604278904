import { CUSTOM_FIELD_MODULE, ENTRY_POINT_EDIT } from '@atlassian/jira-forge-ui-constants';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getEventId } from '../../common/utils';
import { fireOperationalEvent, fireTrackEvent, fireUiEvent } from '../../common/utils/consumers';
import { forgeModulesConcurrentLoadTime } from '../../common/utils/performance-analytics';
import {
	MODULE_TYPE_TRIGGER,
	MODULE_TYPE_EXTENSION,
	TRACK_ACTIONS,
	UI_ACTIONS,
	OPERATIONAL_ACTIONS,
} from '../../constants';

const moduleEventId = getEventId({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
	entryPoint: ENTRY_POINT_EDIT,
});

const triggerEventId = getEventId({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
	entryPoint: ENTRY_POINT_EDIT,
});

export const fireTrackEditedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.EDITED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireUiCanceledEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.CANCELED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalEditFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.EDIT_FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireUiTriggerClickEvent = (
	extensionId: string,
	source?: Source,
	attributes?: Attributes,
) => {
	forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

	return fireUiEvent(UI_ACTIONS.CLICKED, {
		id: triggerEventId,
		source,
		attributes,
	});
};

export const fireOperationalFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});
