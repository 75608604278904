import { useState, useCallback, useRef } from 'react';
import { log } from '@atlassian/jira-common-util-logging';
import { fetchJson } from '@atlassian/jira-fetch';
import type { User } from '../../common/types';

type FetchUsers = (query?: string) => Promise<User[] | undefined>;

const useUsersQuery = (
	autoCompleteUrl: string,
): [
	{
		data: User[];
		error?: Error;
		loading: boolean;
	},
	FetchUsers,
] => {
	const [users, setUsers] = useState<User[]>([]);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	const currentQuery = useRef('');

	const fetchUsers = useCallback<FetchUsers>(
		// @ts-expect-error - TS2345 - Argument of type '(query?: string | undefined) => Promise<void> | Promise<never[]>' is not assignable to parameter of type 'FetchUsers'.
		(query = '') => {
			if (['', null, undefined].includes(autoCompleteUrl)) {
				return Promise.resolve([]);
			}

			const usersFetchUrl = `${autoCompleteUrl || ''}${query}`;

			setLoading(true);
			currentQuery.current = query;

			if (error) {
				setError(undefined);
			}

			return fetchJson(usersFetchUrl)
				.then((response: User[]) => {
					if (query === currentQuery.current) {
						setUsers(response);
					}
				})
				.catch((err: Error) => {
					if (query === currentQuery.current) {
						setError(err);
					}
					log.safeErrorWithoutCustomerData('issue.fields.assigne', 'Failed to fetch users', err);
				})
				.finally(() => setLoading(false));
		},
		[autoCompleteUrl, error],
	);

	return [{ data: users, error, loading }, fetchUsers];
};

export default useUsersQuery;
