import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import AppBase from '@atlassian/jira-app-base';
import { FormSubmissionRoot } from '@atlassian/jira-business-form/src/ui/form-submission';
import { FormSubmissionContextProvider } from '@atlassian/jira-business-form/src/ui/form-submission/form-submission-context-provider';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication';
import UFOSegment from '@atlassian/jira-ufo-segment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JiraBusinessFormAppType = Record<any, any>;

const JiraBusinessFormApp: ComponentType<JiraBusinessFormAppType> = () => (
	<UFOSegment name="business-form-submit-app">
		<AuthenticationCheck>
			<AppBase id="business">
				<FormSubmissionContextProvider>
					<FormSubmissionRoot />
				</FormSubmissionContextProvider>
				<SubProductUpdater subProduct="core" />
			</AppBase>
		</AuthenticationCheck>
	</UFOSegment>
);

export default JiraBusinessFormApp;
