import React from 'react';
import type { MediaFile } from '@atlaskit/media-picker';
import { AttachmentsField } from '@atlassian/jira-issue-create-commons';
import { ATTACHMENT_TYPE } from '@atlassian/jira-platform-field-config';
import { AttachmentFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { attachmentFieldWriter } from '../writers';

const AttachmentFieldTypeComponent = (props: ProvidedFieldProps) =>
	props.mediaUploadContexts.attachmentUploadContext ? (
		<AttachmentsField
			{...props}
			mediaUploadContext={props.mediaUploadContexts.attachmentUploadContext}
		/>
	) : null;

export const AttachmentFieldTypeConfig: FieldTypeConfig<MediaFile[]> = {
	type: ATTACHMENT_TYPE,
	Component: AttachmentFieldTypeComponent,
	placeholder: <AttachmentFieldPlaceholder />,
	writer: attachmentFieldWriter,
};
