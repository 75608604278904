import React from 'react';
import Textfield from '@atlaskit/textfield';
import { validateString } from '../../../../utils/validators';
import { withFormField } from '../../../with-form-field';
import type { Props, FieldProps, InputFieldConfig, FieldValue } from './types';
import { preventMouseScrollForNumericFieldHandler } from './utils';

const InputField = (props: Props) => {
	const {
		type = 'text',
		width = 'auto',
		error,
		fieldProps: { value = '', onChange, ...rest },
		autoFocus,
		isReadOnly = false,
	} = props;

	return (
		<Textfield
			{...rest}
			// @ts-expect-error - TS2322 - Type '(value: string) => void' is not assignable to type 'FormEventHandler<HTMLInputElement>'.
			onChange={onChange}
			value={value}
			type={type}
			width={width}
			step="any"
			testId="issue-create-commons.common.ui.fields.base-fields.input-field.textfield"
			isInvalid={Boolean(error)}
			autoFocus={autoFocus}
			onWheel={preventMouseScrollForNumericFieldHandler}
			isDisabled={isReadOnly || rest.isDisabled}
		/>
	);
};

export default withFormField({ validator: validateString })<InputFieldConfig, FieldValue>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<InputFieldConfig, string>, any>'.
	InputField,
);

export { InputField };
export type { FieldProps, InputFieldConfig, FieldValue };
