import { expandWithoutNestedExpand as expandWithoutNestedExpandFactory, expand as expandFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name expand_node
 */

/**
 * @name expand_with_no_mark_node
 */

/**
 * @name expand_with_breakout_mark_node
 */

function getExpandAttrs(domNode) {
  const dom = domNode;
  return {
    title: dom.getAttribute('data-title'),
    __expanded: true
  };
}
const nodeSpecOptions = {
  parseDOM: [{
    context: 'table//',
    tag: 'div[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    context: 'expand//',
    tag: '[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    context: 'nestedExpand//',
    tag: '[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    tag: '[data-node-type="nestedExpand"] button',
    ignore: true
  }, {
    tag: '[data-node-type="expand"] button',
    ignore: true
  }, {
    tag: 'div[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'expand',
      'data-title': node.attrs.title,
      'data-expanded': node.attrs.__expanded
    };
    return ['div', attrs, 0];
  }
};
export const expand = expandWithoutNestedExpandFactory(nodeSpecOptions);
export const expandWithNestedExpand = expandFactory(nodeSpecOptions);
export const toJSON = node => ({
  attrs: Object.keys(node.attrs).filter(key => !key.startsWith('__')).reduce((obj, key) => {
    return {
      ...obj,
      [key]: node.attrs[key]
    };
  }, {})
});