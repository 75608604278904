import React from 'react';
import { LabelsField } from '@atlassian/jira-issue-create-commons';
import type { Option } from '@atlassian/jira-issue-field-labels';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { labelsFieldWriter } from '../writers';

const LabelsFieldTypeComponent = (props: ProvidedFieldProps) => <LabelsField {...props} />;

export const LabelsFieldTypeConfig: FieldTypeConfig<Option[]> = {
	type: LABELS_TYPE,
	Component: LabelsFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: labelsFieldWriter,
};
