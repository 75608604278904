import type { ProjectType } from '@atlassian/jira-common-constants';
// itsm practices - copied from src/packages/issue-create/issue-create-common-types/src/common/types/index.tsx
export const SERVICE_REQUEST = 'SERVICE_REQUEST' as const;
export const INCIDENT_MANAGEMENT = 'INCIDENT_MANAGEMENT' as const;
export const PROBLEM_MANAGEMENT = 'PROBLEM_MANAGEMENT' as const;
export const CHANGE_MANAGEMENT = 'CHANGE_MANAGEMENT' as const;
export const POST_INCIDENT_REVIEW = 'POST_INCIDENT_REVIEW' as const;
// cs practice
export const DEVELOPER_ESCALATION = 'DEVELOPER_ESCALATION' as const;
export const UNASSIGNED_REQUEST_TYPE = 'UNASSIGNED_REQUEST_TYPE' as const;

export type RequestTypePractices =
	| typeof SERVICE_REQUEST
	| typeof INCIDENT_MANAGEMENT
	| typeof PROBLEM_MANAGEMENT
	| typeof CHANGE_MANAGEMENT
	| typeof POST_INCIDENT_REVIEW
	| typeof DEVELOPER_ESCALATION
	| typeof REQUEST_TYPE
	| typeof UNASSIGNED_REQUEST_TYPE;

export type Project = {
	label: string;
	value: string;
	iconUrl: string;
	projectKey: string;
	projectType: ProjectType;
	isSimplified: boolean;
	classificationTags?: string[];
};
export type SelectedProject = Project | null;

// fallback to REQUEST_TYPE if practices are not specified
export const REQUEST_TYPE = 'REQUEST_TYPE' as const;
export const ISSUE_TYPE = 'ISSUE_TYPE' as const;

export type FieldProps<FieldValue> = {
	isDisabled: boolean;
	isInvalid: boolean;
	value: FieldValue;
	onChange: (value: FieldValue | null, ...rest: unknown[]) => void;
	onBlur: () => void;
	onFocus: () => void;
	isRequired?: boolean;
	id?: string;
	'aria-labelledby'?: string;
};

export type IssueType = {
	label: string;
	value: string;
	iconUrl: string;
	type: typeof ISSUE_TYPE;
};

export type RequestType = {
	label: string;
	value: string;
	description: string;
	helpText: string;
	iconUrl: string;
	requestTypeKey: string;
	issueTypeId: string;
	issueTypeName?: string | undefined;
	practice: RequestTypePractices;
	type: typeof REQUEST_TYPE;
};

export type IssueOrRequestType = IssueType | RequestType | null;
