import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { AvatarUrls } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { UserObject, UserOption } from './types';

export const mapUserObjectToUserOption = (
	userData: {
		readonly avatarUrl?: string;
		readonly avatarUrls?: Readonly<AvatarUrls>;
		readonly displayName?: string;
		readonly accountId?: string | null;
		readonly emailAddress?: string | null;
	},
	shouldShowEmailInOptions = false,
): UserOption => {
	let userOption = {
		id: userData.accountId || '',
		name: userData.displayName || '',
		avatarUrl: userData?.avatarUrl || userData.avatarUrls?.['32x32'] || '',
	};
	if (
		shouldShowEmailInOptions === true &&
		userData.emailAddress !== null &&
		userData.emailAddress !== undefined
	) {
		// @ts-expect-error - TS2322 - Type '{ byline: string; id: string; name: string; avatarUrl: string; }' is not assignable to type '{ id: string; name: string; avatarUrl: string; }'.
		userOption = { ...userOption, byline: userData.emailAddress };
	}
	return userOption;
};

export const mapAutoCompleteResponseToUsers = (
	autoCompleteResponse: unknown,
	isBulkEdit: boolean,
): UserObject[] => {
	try {
		if (isBulkEdit) {
			if (
				autoCompleteResponse &&
				typeof autoCompleteResponse === 'object' &&
				'users' in autoCompleteResponse &&
				autoCompleteResponse.users
			) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return autoCompleteResponse.users as UserObject[];
			}
		} else if (Array.isArray(autoCompleteResponse)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return autoCompleteResponse as UserObject[];
		}
		throw Error('autoCompleteResponse is either null or not expected value');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'mapAutoCompleteResponse',
				packageName: 'jiraBaseUserPicker',
				teamName: 'gryffindor',
			},
			error,
		});
	}
	return [];
};
