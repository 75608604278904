// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = sc7h9stshcvmkj7nt3ccks24
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/sc7h9stshcvmkj7nt3ccks24
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/lock-red-darkmode.svg';
import srcLight from '../../assets/lock-red.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 169, 222];
export { srcLight, srcDark, viewBox };
