import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { MUTATION_GROUP } from './constants';
import type { LabelData, FieldValue, JiraLabelsFieldInput } from './types';

export const mapDataToLabelOptions = (data: LabelData): FieldValue =>
	data.map(
		({ name }): Option => ({
			value: name,
			label: name,
		}),
	);

export const mutateLabelsFields = (
	selectedLabels: FieldValue,
	fieldId: string,
): JiraLabelsFieldInput => ({
	fieldId,
	labels: selectedLabels.map(({ value }) => ({ name: value })),
	mutationGroup: MUTATION_GROUP,
});
