import React from 'react';
import { defaultUserPickerStyles } from '@atlassian/jira-base-user-picker/src/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateGroupPickerField } from '@atlassian/jira-issue-create-commons/src/common/utils/validators/index.tsx';
import GroupPickerEdit from '@atlassian/jira-issue-field-group-picker/src/ui/edit/index.tsx';
import { closeMenuOnScroll } from '../../common/utils/select';
import type { Props, GroupPickerFieldConfig, FieldValue } from './types';
import { transformGroupDefaultValueToUserOption } from './utils';

const SingleGroupPickerField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange },
		fieldId,
		error,
		autoCompleteUrl,
		autoFocus = false,
		isDropdownMenuFixedAndLayered = false,
		ariaLabelledBy,
	} = props;

	const getExtraProps = () => {
		if (isDropdownMenuFixedAndLayered) {
			return {
				menuPosition: 'fixed',
				groupPickerStyles: defaultUserPickerStyles,
				onCloseMenuOnScroll: closeMenuOnScroll,
			} as const;
		}

		return { onCloseMenuOnScroll: closeMenuOnScroll };
	};

	return (
		<GroupPickerEdit
			autoCompleteUrl={autoCompleteUrl}
			onChange={onChange}
			value={value}
			fieldId={fieldId}
			autoFocus={autoFocus}
			isMulti={false}
			aria-labelledby={ariaLabelledBy}
			width={width}
			isInvalid={Boolean(error)}
			placeholder=" "
			{...getExtraProps()}
		/>
	);
};

export default withFormField({
	validator: validateGroupPickerField,
	transformDefaultValue: transformGroupDefaultValueToUserOption,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<GroupPickerFieldConfig, LegacyValue<UserPickerOption>>, any>'.
})<GroupPickerFieldConfig, FieldValue>(SingleGroupPickerField);
export { mutateSingleGroupPickerField } from './utils';
