import { useEffect } from 'react';
import { useUploadPermissions } from '../../services/media-upload-permissions';
import { type MediaPermissionsProps, useMediaPermissionsStore } from './store';

export const MediaPermissionsPreloader = ({
	projectId,
	formId,
}: {
	projectId: string;
	formId: string;
}) => {
	const { uploadPermissions, fetchUploadPermissions, uploadPermissionStatus } =
		useUploadPermissions();
	const [, { updateStore }] = useMediaPermissionsStore();

	useEffect(() => {
		if (projectId) {
			fetchUploadPermissions(projectId, formId);
		}
	}, [projectId, fetchUploadPermissions, formId]);

	useEffect(() => {
		updateStore({
			mediaUploadPermissions: uploadPermissions,
			mediaUploadPermissionStatus: uploadPermissionStatus,
		});
	}, [updateStore, uploadPermissionStatus, uploadPermissions]);

	return null;
};

export const useMediaPermissions = () => {
	const [{ mediaUploadPermissions, mediaUploadPermissionStatus }] = useMediaPermissionsStore();

	const isUploadPermissionReady =
		mediaUploadPermissionStatus === 'COMPLETED' && mediaUploadPermissions !== null;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		mediaUploadPermissions,
		mediaUploadPermissionStatus,
		mediaContext: {
			viewContext: isUploadPermissionReady ? mediaUploadPermissions : {},
			uploadContext: isUploadPermissionReady ? mediaUploadPermissions : {},
			userAuth: null,
		},
	} as unknown as MediaPermissionsProps;
};
