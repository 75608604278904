import type { FormatMessage } from '@atlassian/jira-shared-types';
import type {
	VersionOptionExternalRest,
	VersionOption,
	VersionOptionExternal,
	GroupedOptions,
	Status,
} from '../../common/types';
import messages from '../../messages';

const getStatusFromRestOption = (option: VersionOptionExternalRest): Status => {
	if (option.released) {
		return 'RELEASED';
	}
	if (option.archived) {
		return 'ARCHIVED';
	}
	return 'UNRELEASED';
};

export const transformVersionExternalRestToVersion = (
	option: VersionOptionExternalRest,
): VersionOption => ({
	label: option.name,
	value: option.id,
	status: getStatusFromRestOption(option),
});

export const transformVersionExternalToVersion = (
	option: VersionOptionExternal,
): VersionOption => ({
	label: option.name,
	value: option.id,
	status: option.status,
});

export const removeArchivedOptions = (options: VersionOption[]): VersionOption[] =>
	options.filter((option) => option.status !== 'ARCHIVED');

export const getGroupedOptions = (
	options: VersionOption[],
	formatMessage: FormatMessage,
): GroupedOptions[] => {
	const groupedOptions: GroupedOptions[] = [];
	// archived options are assumed to be filtered out already since we don't want to display them. Here, we are grouping according to 'status' being RELEASED or UNRELEASED
	if (options.length) {
		// the order of versions is decided from the first option since the API response follows the order
		const versionOrder =
			options.length && options[0].status === 'RELEASED'
				? ['RELEASED', 'UNRELEASED']
				: ['UNRELEASED', 'RELEASED'];

		// @ts-expect-error - TS2345 - Argument of type '(status: Status) => void' is not assignable to parameter of type '(value: string, index: number, array: string[]) => void'.
		versionOrder.forEach((status: Status) => {
			const statusOptions = options.filter((option) => option.status === status);
			if (statusOptions.length) {
				groupedOptions.push({
					options: statusOptions,
					label:
						status === 'RELEASED'
							? formatMessage(messages.released)
							: formatMessage(messages.unreleased),
				});
			}
		});
	}

	return groupedOptions;
};

export const getVersionsIds = (
	selectedVersions: VersionOption[],
): Partial<
	Flow.Diff<
		VersionOptionExternal,
		{
			name: string;
			status?: Status;
		}
	>
>[] =>
	selectedVersions.map((v) => ({
		id: v.value,
	}));

export const getVersionId = (
	selectedVersion: VersionOption,
): Partial<
	Flow.Diff<
		VersionOptionExternal,
		{
			name: string;
			status?: Status;
		}
	>
> => ({
	id: selectedVersion.value,
});
