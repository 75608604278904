import React, { useCallback, type MouseEventHandler, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import Checkbox from '@atlaskit/checkbox';
import { components, type MenuProps } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ParentFieldOption } from '../../../common/types';
import messages from './messages';

type Props = MenuProps<ParentFieldOption, false> & {
	selectProps?: {
		componentsProps?: {
			isChecked?: boolean;
			onChange?: MouseEventHandler<HTMLDivElement | HTMLLabelElement>;
		};
	};
};

const Menu = (props: Props) => {
	const { formatMessage } = useIntl();
	const { children, ...restProps } = props;
	const isChecked = props.selectProps?.componentsProps?.isChecked || false;
	const label = formatMessage(messages.checkboxLabel);

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		// stopping propagation when focus moves to checkbox because select already binded with space and enter for selection
		if (event.key === ' ') {
			event.stopPropagation();
		}
	}, []);

	/**
	 * When the checkbox is clicked, we need to stop the event propagation to prevent the select from closing.
	 */
	const onChange = useCallback(
		(event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLLabelElement>) => {
			// stopping propagation when checkbox is clicked to prevent select from closing
			event.stopPropagation();
			event.preventDefault();
			props.selectProps?.componentsProps?.onChange?.(event);
		},
		[props.selectProps?.componentsProps],
	);

	return (
		<components.Menu {...restProps}>
			<CheckboxWrapper>
				<Checkbox
					onKeyDown={onKeyDown}
					onClick={onChange}
					// this needs to be no-op to make this a controlled component
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onChange={() => {}}
					isChecked={isChecked}
					size="medium"
					id="issue-field-parent.ui.edit.menu.checkbox"
				/>
				<CheckboxLabel
					onClick={onChange}
					htmlFor="issue-field-parent.ui.edit.menu.checkbox"
					data-testid="issue-field-parent.ui.edit.menu.checkbox-label"
				>
					{label}
				</CheckboxLabel>
			</CheckboxWrapper>
			{children}
		</components.Menu>
	);
};

export default Menu;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}
              ${token('space.100', '8px')} ${token('space.150', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `2px solid ${token('color.border', colors.N30)}`,
	alignItems: 'center',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxLabel = styled.label({
	padding: 0,
});
