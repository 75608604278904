export type BaseJiraBusinessForm = {
	formId: string;
	title: string;
	description: string;
	bannerColor?: string;
};

export const FormAccessLevelTypes = {
	LIMITED: 'limited',
	OPEN: 'open',
} as const;

export type FormAccessLevelTypes = (typeof FormAccessLevelTypes)[keyof typeof FormAccessLevelTypes];

export type InexactBaseJiraBusinessForm = BaseJiraBusinessForm;
