import React, { type KeyboardEvent, type ReactElement, type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type Color,
	type ColorSchema,
	MAP_COLORS_TO_VALUE,
	transformColorToTheme,
} from '@atlassian/jira-issue-epic-color';
import type { IssueParent } from '@atlassian/jira-issue-parent-services';
import type { ParentFieldOption, LozengeThemes } from './types';

export const mapColor = (color: Color | undefined | '') =>
	color === undefined || color === '' ? 'transparent' : MAP_COLORS_TO_VALUE[color];

export const getOptionColorElem = (
	color: Color | undefined | '',
): ReactElement<ComponentPropsWithoutRef<typeof ColorPatch>> => (
	<ColorPatch color={color} data-testid={`issue-field-parent.common.color-patch-${color}`} />
);

export const mapDataToOptions = (issueParents: IssueParent[]): ParentFieldOption[] => {
	const transformedResponse = ff(
		'request-isdone-when-requesting-parent-candidates-and-display-in-parent-field_3becy',
	)
		? issueParents.map(({ id, issueType, key, summary, color, isDone }: IssueParent) => ({
				id,
				key,
				fields: {
					issuetype: issueType,
					summary,
				},
				color,
				value: id,
				label: `${key} ${summary}`,
				isDone,
			}))
		: issueParents.map(({ id, issueType, key, summary, color }: IssueParent) => ({
				id,
				key,
				fields: {
					issuetype: issueType,
					summary,
				},
				color,
				value: id,
				label: `${key} ${summary}`,
			}));
	return transformedResponse;
};

export const mapOptionToData = (option: ParentFieldOption) => ({
	id: option.value,
});

export const getLozengeThemes: Record<Color, LozengeThemes> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

export const preventEscKeyPropagationKeyPressHandler = (
	event: KeyboardEvent | KeyboardEvent<EventTarget>,
) => {
	if (event?.key === 'Escape') {
		event?.nativeEvent?.stopImmediatePropagation();
	}
};

export const getLozengeThemeByColor = (color: Color): LozengeThemes => getLozengeThemes[color];

// We adjust the size of the color patch away from normal grid sizes because
// the issue type icon actually has a 1px border on each side, which is 2x1px on width/height
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPatch = styled.div<{
	color: Color | undefined | '';
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2 - 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 2 - 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2 - 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => mapColor(color) /* stylelint-disable-line */,
});
