import { useEffect } from 'react';
import { useIssueAdjustmentsActions } from '../../../controllers/issue-adjustments';

export const IssueAdjustmentsErrorHandler = (): null => {
	const { setError, resetError } = useIssueAdjustmentsActions();

	useEffect(() => {
		setError({
			errorCode: 'ERROR_BOUNDARY_HANDLER_ERROR_CODE',
		});

		return () => resetError();
	}, [setError, resetError]);

	return null;
};
