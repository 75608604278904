import { useCallback, useMemo, useState } from 'react';
import performance from '@atlassian/jira-common-performance';
import { performGetRequest } from '@atlassian/jira-fetch';
import {
	fireSuggestionsRequestSuccessEvent,
	fireSuggestionsRequestFailedEvent,
	type SessionId,
} from '@atlassian/jira-issue-analytics';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types';
import type { Props, UseLabelsSuggestions } from './types';

export const getLabelsSuggestionsFromServer = async (
	autoCompleteUrl: string,
	query: string,
	sessionId?: SessionId,
) => {
	let url = `${autoCompleteUrl}${encodeURIComponent(query)}`;

	if (sessionId !== undefined && sessionId !== null) {
		url = `${url}&sessionId=${sessionId}`;
	}

	return performGetRequest(url);
};

export const useLabelsSuggestions: UseLabelsSuggestions = (props: Props) => {
	const { fieldId, getSuggestions = getLabelsSuggestionsFromServer } = props;

	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getLabelsSuggestions = useCallback(
		async (autoCompleteUrl: string, query: string, sessionId?: SessionId) => {
			const startTime = performance.now();
			const queryLength = query.length;

			try {
				const response: LabelsSuggestionList = await getSuggestions(
					autoCompleteUrl,
					query,
					sessionId,
				);

				if (fieldId === LABELS_TYPE) {
					fireSuggestionsRequestSuccessEvent(LABELS_TYPE, startTime, createAnalyticsEvent, {
						sessionId,
						queryLength,
					});
				}

				setIsLoadingSuggestions(false);

				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (fieldId === LABELS_TYPE) {
					fireSuggestionsRequestFailedEvent(LABELS_TYPE, startTime, createAnalyticsEvent, {
						sessionId,
					});
				}

				setIsLoadingSuggestions(false);

				throw error;
			}
		},
		[createAnalyticsEvent, fieldId, getSuggestions, setIsLoadingSuggestions],
	);

	const actions = useMemo(
		() => ({
			getLabelsSuggestions,
			setIsLoadingSuggestions,
		}),
		[getLabelsSuggestions, setIsLoadingSuggestions],
	);

	return [{ isLoadingSuggestions }, actions];
};
