import React, { type ComponentPropsWithoutRef, useState, useCallback } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import { fireTrackEditedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit';
import { EditAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field';
import { AsyncCustomFieldInlineEdit } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field-inline-edit/async';
import { ErrorPanel } from '@atlassian/jira-forge-ui-renderers/src/common/ui/error-panel';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types';
import { useIntl } from '@atlassian/jira-intl';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { transformValue } from '../utils';
import messages from './messages';

type LazyCustomFieldProps = Pick<
	ComponentPropsWithoutRef<typeof AsyncCustomFieldInlineEdit>,
	'extension' | 'extensionData' | 'extensionPayload'
>;

type Props = LazyCustomFieldProps & {
	analyticsAttributes: Attributes;
	onChange: (value: ForgeCustomFieldValue) => void;
};

export const EditModeView = ({
	extension,
	extensionData,
	extensionPayload,
	analyticsAttributes,
	onChange,
}: Props) => {
	const [isModalMode, setIsModalMode] = useState(false);
	const intl = useIntl();
	const onEditConfirm = useCallback(
		(newValue: ForgeCustomFieldValue) => {
			onChange(transformValue(newValue, extension));
			fireTrackEditedEvent('issue-create', analyticsAttributes);
		},
		[extension, analyticsAttributes, onChange],
	);

	const onModalRender = useCallback(() => setIsModalMode(true), []);

	if (isModalMode) {
		// temporary for UI Kit version of 1.2.0 when inline edit is not supported
		// will be fixed with https://ecosystem-platform.atlassian.net/browse/ARKEN-32
		const translatedError = {
			message: intl.formatMessage(messages.modalModeErrorTitle),
			errorMessage: intl.formatMessage(messages.modalModeErrorDescription),
		};

		log.safeErrorWithoutCustomerData(
			'jiraForgeUI.customFieldEdit',
			'App tried to render modal expierience on issue create',
			{
				...analyticsAttributes,
				extension: JSON.stringify(extension),
			},
		);

		return <ErrorPanel error={translatedError} />;
	}

	return (
		<AsyncCustomFieldInlineEdit
			extension={extension}
			extensionData={extensionData}
			analyticsAttributes={analyticsAttributes}
			extensionPayload={extensionPayload}
			onSubmit={onEditConfirm}
			onModalRender={onModalRender}
		/>
	);
};

export const EditMode = (props: Props) => (
	<EditAnalyticsWrapper attributes={getAnalyticsAttributesFromExtension(props.extension)}>
		<EditModeView {...props} />
	</EditAnalyticsWrapper>
);
