/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import Popup from '@atlaskit/popup';
import { Box, xcss, Text, Stack } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/jira-router';
import messages from './messages';
import type { Props, PopupTriggerProps } from './types';

const PARENT_CHANGEBOARDING_UI_EVENT = 'parentFieldChangeboarding';

export const spotlightUrl =
	'https://community.atlassian.com/t5/Jira-Software-articles/Introducing-the-new-Parent-field-in-company-managed-projects/ba-p/2377758';

export const learnMoreUrl =
	'https://support.atlassian.com/jira-cloud-administration/docs/what-are-issue-types/#What-are-parent-and-child-issues';

export const Changeboarding = (props: Props) => {
	const { leftPosition, defaultOpen } = props;

	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(defaultOpen || false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickInfo = useCallback(() => {
		setIsOpen(!isOpen);

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'infoButton',
		});

		fireUIAnalytics(analyticsEvent, PARENT_CHANGEBOARDING_UI_EVENT);
	}, [isOpen, setIsOpen, createAnalyticsEvent]);

	const onInfolinkClicked = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'learnMoreLink',
		});

		fireUIAnalytics(analyticsEvent, PARENT_CHANGEBOARDING_UI_EVENT);
	}, [createAnalyticsEvent]);

	const getInfoIconButton = (triggerProps: PopupTriggerProps) => (
		<Button
			{...triggerProps}
			appearance="subtle-link"
			data-ds--inline-message--button
			spacing="none"
			isSelected={isOpen}
			onClick={() => onClickInfo()}
			aria-label={formatMessage(messages.spotligthLearnMore)}
			iconAfter={
				<EditorPanelIcon
					label={formatMessage(messages.spotligthLearnMore)}
					testId="issue-field-parent.ui.changeboarding.editor-panel-icon"
				/>
			}
		/>
	);

	const getInfoIconButtonWrapper = (triggerProps: PopupTriggerProps) => {
		if (leftPosition) {
			return (
				<Box
					xcss={[
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						xcss({
							position: 'absolute',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
							left: `${leftPosition}px`,
							top: '-1px',
						}),
					]}
				>
					{getInfoIconButton(triggerProps)}
				</Box>
			);
		}

		return (
			<Box xcss={infoButtonContainerStyles}>
				<Box xcss={infoButtonStyles}>{getInfoIconButton(triggerProps)}</Box>
			</Box>
		);
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			shouldRenderToParent
			placement="right"
			zIndex={layers.tooltip()}
			content={({ setInitialFocusRef }) => (
				<Box xcss={popupContentStyles}>
					{fg('visual-refresh-typography-temp') ? (
						<Stack space="space.150">
							<Text as="p">{formatMessage(messages.infoMessage)}</Text>
							<Text as="p">
								<Link
									ref={setInitialFocusRef}
									target="_blank"
									href={learnMoreUrl}
									onClick={() => onInfolinkClicked()}
								>
									{formatMessage(messages.infoLearnMore)}
								</Link>
							</Text>
						</Stack>
					) : (
						<>
							<p>{formatMessage(messages.infoMessage)}</p>
							<p>
								<Link
									ref={setInitialFocusRef}
									target="_blank"
									href={learnMoreUrl}
									onClick={() => onInfolinkClicked()}
								>
									{formatMessage(messages.infoLearnMore)}
								</Link>
							</p>
						</>
					)}
				</Box>
			)}
			trigger={(triggerProps) => getInfoIconButtonWrapper(triggerProps)}
		/>
	);
};

const infoButtonContainerStyles = xcss({
	display: 'inline-block',
	width: '20px',
});

const infoButtonStyles = xcss({
	position: 'absolute',
	top: '6px',
});

const popupContentStyles = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	width: '300px',
});
