import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics';
import { getSupportedFieldConfiguration } from '@atlassian/jira-ui-modifications-fields-configuration';
import type { FieldInternalMetadata } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field-internal-metadata';
import type {
	LookupData,
	FieldPublicShape,
	FieldType,
	FieldInternalShape,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/types';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';

export const transformFieldToIframe = (
	fieldChanges: FieldInternalShape,
	viewType: ViewType,
	fieldType: FieldType,
	lookupData: LookupData,
	fieldFromIframe: FieldPublicShape,
	fieldMetadata: FieldInternalMetadata,
): FieldPublicShape => {
	const fieldConfiguration = getSupportedFieldConfiguration(viewType, fieldType);
	const fieldInternalToPublicTransformers = fieldConfiguration?.internalToPublicTransformers;

	const publicShapeField: FieldPublicShape = { ...fieldChanges };

	if (publicShapeField.value !== undefined && fieldInternalToPublicTransformers?.value) {
		try {
			publicShapeField.value = fieldInternalToPublicTransformers?.value(
				fieldChanges.value,
				fieldMetadata.fieldRenderer,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireIAErrorAnalytics({
				error: err,
				id: 'internalToPublicValueTransformer',
				viewType,
				attributes: {
					fieldType,
				},
			});
		}
	}

	if (
		fieldFromIframe.optionsVisibility !== undefined &&
		fieldInternalToPublicTransformers?.optionsVisibility
	) {
		try {
			const lookupSource = fieldConfiguration?.lookupSource;
			const lookupValues = lookupSource ? lookupData[lookupSource] : undefined;

			publicShapeField.optionsVisibility = fieldInternalToPublicTransformers?.optionsVisibility(
				fieldFromIframe.optionsVisibility,
				lookupValues,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireIAErrorAnalytics({
				error: err,
				id: 'internalToPublicOptionsVisibilityTransformer',
				viewType,
				attributes: {
					fieldType,
				},
			});
		}
	}

	return publicShapeField;
};
