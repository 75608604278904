import type { ApolloError } from 'apollo-client';
import type { MediaContext } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { type Action, createHook, createStore } from '@atlassian/react-sweet-state';
import type { JiraBusinessFormWithFieldData } from '../../../services/get-form/types';

export type StoreType = {
	data?: JiraBusinessFormWithFieldData;
	loading: boolean;
	error?: ApolloError;
};

export type MediaPermissionsProps = StoreType & {
	mediaContext: MediaContext | null;
};

const actions = {
	updateStore:
		(newData: StoreType): Action<StoreType> =>
		({ setState }) => {
			setState(newData);
		},
};

type Actions = typeof actions;

const FormSubmissionStore = createStore<StoreType, Actions>({
	initialState: {
		data: undefined,
		loading: false,
		error: undefined,
	},
	actions,
	name: 'JWMFormSubmission',
});

export const useFormSubmissionStore = createHook(FormSubmissionStore);
