import isEqual from 'lodash/isEqual';
import { fg } from '@atlassian/jira-feature-gating';
import { fireOperationalAnalyticsWithoutContext } from '@atlassian/jira-ui-modifications-analytics';
import type {
	FieldId,
	FieldMapInternalMetadata,
	FormDataPublic,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common';
import type { FieldType } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views/types';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';
import { mapFieldValueFromInternalToPublic } from '../map-field-value-from-internal-to-public';

export const calculateNextFormValue = ({
	internalFormMetadata,
	formData,
	fieldId,
	fieldValue,
	fieldType,
	viewType,
}: {
	fieldId: FieldId;
	fieldValue: unknown;
	fieldType: FieldType;
	internalFormMetadata: FieldMapInternalMetadata;
	formData: FormDataPublic | null;
	viewType: ViewType;
}) => {
	if (formData === null) {
		return null;
	}

	let newPublicValue;

	if (fg('handle_set_value_for_uninitialized_field')) {
		try {
			newPublicValue = mapFieldValueFromInternalToPublic({
				fieldMetadata: internalFormMetadata[fieldId],
				internalFieldValue: fieldValue,
				fieldType,
				viewType,
			});
		} catch (e) {
			fireOperationalAnalyticsWithoutContext(
				'internalToPublicValueTransformer',
				'fieldMetadataUndefined',
				viewType,
				{
					fieldType,
				},
			);

			return null;
		}
	} else {
		newPublicValue = mapFieldValueFromInternalToPublic({
			fieldMetadata: internalFormMetadata[fieldId],
			internalFieldValue: fieldValue,
			fieldType,
			viewType,
		});
	}

	const currentPublicValue = formData[fieldId]?.value;
	if (isEqual(newPublicValue, currentPublicValue)) {
		return null;
	}

	return {
		...formData,
		[fieldId]: {
			...formData[fieldId],
			value: newPublicValue,
		},
	};
};
