import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { ErrorMessage, HelperMessage } from '@atlaskit/form';
import { ReactRenderer } from '@atlaskit/renderer';
import { B200, N0, N200 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useEmojiProvider } from '@atlassian/jira-emoji-provider/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import { onLinkClick, smartLinksDefault } from '@atlassian/jira-linking-platform-utils';
import { convertWikiToAdf } from '@atlassian/jira-platform-convert-wiki-to-adf/src/main.tsx';
import {
	MAX_ALLOWED_LABELS,
	MAX_LABEL_SIZE,
	MAX_STRING_SIZE,
	LABELS_MAX_ALLOWED,
	LABELS_INVALID,
	FORGE_LABELS_INVALID,
	STRING_INVALID,
} from '../../utils/validators/constants';
import { LEARN_MORE_MESSAGES } from './constants';
import messages from './messages';
import type { Props } from './types';

const FieldMessageBehaviour = (props: Props) => {
	const {
		description = '',
		error,
		fieldName,
		fieldHelpTextUrl = '',
		isMini,
		onLinkClickEvent,
		fieldId,
	} = props;

	const { formatMessage, formatNumber } = useIntl();
	const maxHeight = 22;

	const emojiProvider = useEmojiProvider();

	const getHelpText = useCallback((): string => {
		// fieldHelpTextUrl is used as a unique key. Extracting a suffix just for making the key shorter
		const urlSuffix = fieldHelpTextUrl.substring(fieldHelpTextUrl.lastIndexOf('#'));
		const helperMessage = LEARN_MORE_MESSAGES[urlSuffix] || messages.fieldHelpTextLabel;

		return `[${formatMessage(helperMessage)}|${fieldHelpTextUrl}]`;
	}, [formatMessage, fieldHelpTextUrl]);

	const adfDocument = useMemo(() => {
		let fieldDescription = description;

		if (fieldHelpTextUrl) {
			if (fieldDescription) {
				fieldDescription = `${fieldDescription} ${getHelpText()}`;
			} else {
				fieldDescription = getHelpText();
			}
		}

		return convertWikiToAdf(fieldDescription);
	}, [description, fieldHelpTextUrl, getHelpText]);

	const getErrorMessage = (validationError: string | boolean): string | boolean => {
		switch (validationError) {
			case REQUIRED: {
				return formatMessage(messages.requiredErrorMessage, { fieldName });
			}
			case LABELS_MAX_ALLOWED: {
				return formatMessage(messages.labelsMaxErrorMessage, {
					MAX_ALLOWED_LABELS: formatNumber(MAX_ALLOWED_LABELS),
				});
			}
			case LABELS_INVALID: {
				return formatMessage(messages.labelsInvalidErrorMessage, {
					MAX_LABEL_SIZE: formatNumber(MAX_LABEL_SIZE),
				});
			}
			case STRING_INVALID: {
				return formatMessage(messages.stringInvalidErrorMessage, {
					fieldName,
					MAX_STRING_SIZE: formatNumber(MAX_STRING_SIZE),
				});
			}
			case FORGE_LABELS_INVALID: {
				return formatMessage(messages.forgeLabelsInvalidErrorMessage, {
					MAX_LABEL_SIZE: formatNumber(MAX_LABEL_SIZE),
				});
			}
			default: {
				return validationError;
			}
		}
	};

	if (error !== undefined && error !== false) {
		return <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>;
	}

	const getRenderer = (isTruncated = false) => (
		<ReactRenderer
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			document={adfDocument as DocNode}
			schema={defaultSchema}
			dataProviders={emojiProvider}
			disableHeadingIDs
			featureFlags={{
				codeBidiWarnings: true,
				'code-bidi-warnings': true,
				// enables sending analytics event with renderer specific tti measurement
				// please do not clean up, this feature flag is meant to be rolled out permanently just for a fraction of users
				'renderer-tti-tracking': ff('issue.details.renderer.tti-tracking'),
				'allow-windowed-code-block': true,
			}}
			maxHeight={isTruncated ? maxHeight : undefined}
			truncated={isTruncated}
			fadeOutHeight={isTruncated ? 0 : undefined}
			eventHandlers={{
				smartCard: { onClick: onLinkClick },
				link: { onClick: onLinkClickEvent || onLinkClick },
			}}
			smartLinks={smartLinksDefault}
		/>
	);

	const getRenderedDescription = (truncatedRenderer = false) => (
		<HelperMessage>
			<Description
				data-testid="issue-create-commons.common.ui.field-message.description"
				isMini={isMini}
				{...(fieldId && {
					id: `${fieldId}-field-message`,
				})}
			>
				{getRenderer(truncatedRenderer)}
			</Description>
		</HelperMessage>
	);

	if (adfDocument) {
		if (isMini) {
			if (adfDocument.content[0]?.type === 'paragraph') {
				return (
					<Tooltip
						content={<TooltipContentContainer>{getRenderer()}</TooltipContentContainer>}
						position="bottom"
					>
						{getRenderedDescription(true)}
					</Tooltip>
				);
			}

			return null;
		}

		return getRenderedDescription();
	}

	return null;
};

const FieldMessage = (props: Props) => (
	<FieldMessageBehaviour key={`field-message-${props.fieldId}`} {...props} />
);

export { FieldMessage };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document': {
		color: token('color.text.inverse', N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
		marginTop: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		a: {
			color: token('color.background.accent.blue.subtle', B200),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Description = styled.div<{ isMini?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document': {
		color: token('color.text.subtlest', N200),
		font: token('font.body.UNSAFE_small'),
		marginTop: token('space.050', '4px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		p: (props) =>
			props.isMini && {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
	},
});
