import React from 'react';
import { MultiSelectField } from '@atlassian/jira-issue-create-commons';
import type { SelectOption } from '@atlassian/jira-issue-field-select';
import { MULTI_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { multiSelectFieldWriter } from '../writers';

const MultiSelectCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<MultiSelectField {...props} defaultValue={undefined} />
);

export const MultiSelectCustomFieldTypeConfig: FieldTypeConfig<SelectOption[]> = {
	type: MULTI_SELECT_CF_TYPE,
	Component: MultiSelectCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multiSelectFieldWriter,
};
