// file to be removed with clean up of `jwm-form-field-configs` feature gate
import type { ComponentType } from 'react';
import AssigneeField from '@atlassian/jira-issue-create-commons/src/ui/assignee-field/index.tsx';
import AttachmentsField from '@atlassian/jira-issue-create-commons/src/ui/attachments-field/index.tsx';
import CascadingSelectField from '@atlassian/jira-issue-create-commons/src/ui/cascading-select-field/index.tsx';
import CheckboxSelectField from '@atlassian/jira-issue-create-commons/src/ui/checkbox-select-field/index.tsx';
import CustomTeamsField from '@atlassian/jira-issue-create-commons/src/ui/custom-teams-field/index.tsx';
import DateField from '@atlassian/jira-issue-create-commons/src/ui/date-field/index.tsx';
import DateTimeField from '@atlassian/jira-issue-create-commons/src/ui/date-time-field/index.tsx';
import LabelsField from '@atlassian/jira-issue-create-commons/src/ui/labels-field/index.tsx';
import MultiGroupPickerField from '@atlassian/jira-issue-create-commons/src/ui/multi-grouppicker-field/index.tsx';
import MultiSelectField from '@atlassian/jira-issue-create-commons/src/ui/multi-select-field/index.tsx';
import MultiUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/multi-userpicker-field/index.tsx';
import NumberField from '@atlassian/jira-issue-create-commons/src/ui/number-field/index.tsx';
import PriorityField from '@atlassian/jira-issue-create-commons/src/ui/priority-field/index.tsx';
import RadioSelectField from '@atlassian/jira-issue-create-commons/src/ui/radio-select-field/index.tsx';
import SingleGroupPickerField from '@atlassian/jira-issue-create-commons/src/ui/single-grouppicker-field/index.tsx';
import SingleSelectField from '@atlassian/jira-issue-create-commons/src/ui/single-select-field/index.tsx';
import SingleUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/single-userpicker-field/index.tsx';
import TextField from '@atlassian/jira-issue-create-commons/src/ui/text-field/index.tsx';
import TextAreaField from '@atlassian/jira-issue-create-commons/src/ui/textarea-field/index.tsx';
import UrlField from '@atlassian/jira-issue-create-commons/src/ui/url-field/index.tsx';
import {
	NUMBER_CF_TYPE,
	URL_CF_TYPE,
	TEXT_CF_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DUE_DATE_TYPE,
	SUMMARY_TYPE,
	LABELS_TYPE,
	LABELS_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	USER_CF_TYPE,
	MULTI_USER_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	TEAM_CF_TYPE,
	PRIORITY_TYPE,
	TEXT_AREA_CF_TYPE,
	ATTACHMENT_TYPE,
	DESCRIPTION_TYPE,
	ASSIGNEE_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import DescriptionField from '../../ui/fields/description-field';
import { TeamField } from '../../ui/fields/team-field';
import type { FormSupportedVisibleFieldTypeMap } from '../supported-fields';

type ValueType = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Component: ComponentType<any>;
};

/**
 * Form field mapping based on https://hello.atlassian.net/wiki/spaces/JBus/pages/988271414/Forms+-+Field+support+prioritisation
 */
const fieldMap: FormSupportedVisibleFieldTypeMap<ValueType> = {
	// Quadrant 1
	[SUMMARY_TYPE]: { Component: TextField },
	[ATTACHMENT_TYPE]: { Component: AttachmentsField },
	[DESCRIPTION_TYPE]: { Component: DescriptionField },

	// Quadrant 2
	[SELECT_CF_TYPE]: { Component: SingleSelectField }, // 1
	[TEXT_CF_TYPE]: { Component: TextField }, // 2
	[MULTI_CHECKBOXES_CF_TYPE]: {
		Component: CheckboxSelectField,
	}, // 3
	[TEXT_AREA_CF_TYPE]: {
		Component: TextAreaField,
	}, // 4
	[DUE_DATE_TYPE]: { Component: DateField }, // 5
	[LABELS_TYPE]: {
		Component: LabelsField,
	}, // 6
	[PRIORITY_TYPE]: {
		Component: PriorityField,
	}, // 7
	[DATE_CF_TYPE]: { Component: DateField }, // 8
	[NUMBER_CF_TYPE]: { Component: NumberField }, // 9
	[DATETIME_CF_TYPE]: { Component: DateTimeField }, // 10
	[URL_CF_TYPE]: { Component: UrlField }, // 11
	[MULTI_USER_CF_TYPE]: {
		Component: MultiUserPickerField,
	}, // 12
	[CASCADING_SELECT_CF_TYPE]: {
		Component: CascadingSelectField,
	}, // 13
	[USER_CF_TYPE]: {
		Component: SingleUserPickerField,
	}, // 14
	[RADIO_BUTTONS_CF_TYPE]: {
		Component: RadioSelectField,
	}, // 15
	[MULTI_SELECT_CF_TYPE]: {
		Component: MultiSelectField,
	}, // 16

	// Quadrant 3
	[ASSIGNEE_TYPE]: {
		Component: AssigneeField,
	}, // 1
	[LABELS_CF_TYPE]: { Component: LabelsField }, // 4
	[GROUP_CF_TYPE]: {
		Component: SingleGroupPickerField,
	}, // 6
	[MULTI_GROUP_CF_TYPE]: {
		Component: MultiGroupPickerField,
	}, // 7
	[TEAM_CF_TYPE]: {
		Component: CustomTeamsField,
	}, // 8
	[TEAMS_PLATFORM_CF_TYPE]: {
		Component: TeamField,
	},
};

export const getField = (fieldType: string): ValueType | undefined => fieldMap[fieldType];
