import React from 'react';
import { LabelsField } from '@atlassian/jira-issue-create-commons';
import type { Option } from '@atlassian/jira-issue-field-labels';
import { LABELS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { labelsFieldWriter } from '../writers';

const LabelsCustomFieldTypeComponent = (props: ProvidedFieldProps) => <LabelsField {...props} />;

export const LabelsCustomFieldTypeConfig: FieldTypeConfig<Option[]> = {
	type: LABELS_CF_TYPE,
	Component: LabelsCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: labelsFieldWriter,
};
