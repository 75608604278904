import type { SelectOption, OptionExternalData } from './types';

export const mapSelectOptionsToData = (option: SelectOption): OptionExternalData => ({
	id: option.value,
	value: option.label,
});

export const mapMultiSelectOptionsToData = (options: SelectOption[]): OptionExternalData[] =>
	options.map(
		(option: SelectOption): OptionExternalData => ({
			id: option.value,
			value: option.label,
		}),
	);

export const mapDataToSelectOption = (optionExternal: OptionExternalData): SelectOption => ({
	label: optionExternal.value,
	value: optionExternal.id,
});

export const mapDataToSelectOptions = (data: OptionExternalData[]): SelectOption[] =>
	data.map(
		(optionExternal: OptionExternalData): SelectOption => mapDataToSelectOption(optionExternal),
	);
