import React from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import AtlassianTeamsField from '@atlassian/jira-issue-create-commons/src/ui/atlassian-teams-field/index.tsx';
import type { AtlassianTeamFieldConfig } from '@atlassian/jira-issue-create-commons/src/ui/atlassian-teams-field/types';

const CLICKED_KEY = 'clicked';
const ONBOARDING_BANNER_KEY = 'TEAM_FIELD_ONBOARDING_BANNER_DISMISSED';
const localStorage = createLocalStorageProvider('team-field-onboarding');

export const TeamField = (props: AtlassianTeamFieldConfig) => {
	// We need to override the localstorage so we don't get any of the onboarding for the form
	localStorage.set(CLICKED_KEY, true);
	localStorage.set(ONBOARDING_BANNER_KEY, true);

	return <AtlassianTeamsField {...props} />;
};
