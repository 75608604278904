import React from 'react';
import { CustomTeamsField } from '@atlassian/jira-issue-create-commons';
import type { TeamFieldType } from '@atlassian/jira-issue-field-custom-team';
import { TEAM_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { teamFieldWriter } from '../writers';

const TeamCustomFieldTypeComponent = (props: ProvidedFieldProps) => <CustomTeamsField {...props} />;

export const TeamCustomFieldTypeConfig: FieldTypeConfig<TeamFieldType> = {
	type: TEAM_CF_TYPE,
	Component: TeamCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: teamFieldWriter,
};
