import React from 'react';
import { SingleGroupPickerField } from '@atlassian/jira-issue-create-commons';
import type { UserPickerOption } from '@atlassian/jira-issue-field-group-picker';
import { GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { singleGroupPickerFieldWriter } from '../writers';

const GroupCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<SingleGroupPickerField {...props} />
);

export const GroupCustomFieldTypeConfig: FieldTypeConfig<UserPickerOption> = {
	type: GROUP_CF_TYPE,
	Component: GroupCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: singleGroupPickerFieldWriter,
};
