import React, { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import UserPicker, { type Value } from '@atlaskit/user-picker';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { USER_PICKER_EMPTY } from './constants';
import messages from './messages';
import type { UserPickerProps, UserOption, UserObject } from './types';
import { mapAutoCompleteResponseToUsers, mapUserObjectToUserOption } from './utils';

const stringContains = (str?: string | null, substr?: string | null) => {
	if (str == null) {
		return false;
	}

	if (substr == null || substr === '') {
		return true;
	}

	return str.toLowerCase().includes(substr.toLowerCase());
};

export const FETCH_DEBOUNCE = 400;

const UserPickerWrapper = (props: UserPickerProps) => {
	const {
		allowEmail = false,
		isDisabled,
		isMulti = false,
		value = null,
		emptyOption = null,
		appearance = 'compact',
		onChange = noop,
		onCancel = noop,
		onSelection = noop,
		onDataRequest = noop,
		onDataLoaded = noop,
		isClearable = false,
		portalElement = undefined,
		isDropdownVisibleByDefault,
		menuPosition = undefined,
		onCloseMenuOnScroll,
		autoFocus,
		fieldId,
		placeholder,
		width,
		autoCompleteUrl,
		noBorder,
		styles,
		shouldShowEmailInOptions = false,
		withoutDebounce = false,
		inputId,
		isBulkEdit = false,
		openMenuOnClick = false,
		isRequired = false,
		...fieldProps
	} = props;

	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(
		Boolean(isDropdownVisibleByDefault),
	);

	const { formatMessage } = useIntl();

	// null instead of '' because:
	// 1. fetchSuggestions is called as part of useEffect with query as one of the dependencies
	// 2. we dont want to call fetchSuggestions on mount, so added a check query !== null
	// 3. we want to call fetchSuggestions when query is '' and when picker gets focused (we set query as '') when it gets focused
	const [query, setQueryString] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [wasFocused, setWasFocused] = useState<boolean>(false);
	const [wasCleared, setWasCleared] = useState<boolean>(false);
	const [suggestions, setSuggestions] = useState<UserOption[]>([]);
	const [sessionId, setSessionId] = useState<string>('');

	const getEmptyValue = (): UserOption | null =>
		emptyOption
			? {
					...emptyOption,
					id: USER_PICKER_EMPTY,
				}
			: null;

	const debounceTime = withoutDebounce ? 0 : FETCH_DEBOUNCE;

	const requestSuggestionsDebounced = useMemo(() => {
		const isCurrentValue = (userOptionValue: UserOption): boolean => {
			if (!userOptionValue) {
				return value === null;
			}
			if (Array.isArray(value)) {
				return value.length > 0
					? !!value.find((suggestion) => suggestion.id === userOptionValue.id)
					: userOptionValue.id === USER_PICKER_EMPTY;
			}
			return value ? userOptionValue.id === value.id : userOptionValue.id === USER_PICKER_EMPTY;
		};

		const fetchSuggestions = async (): Promise<UserOption[]> => {
			let users: UserOption[] | UserObject[] = [];
			if (autoCompleteUrl !== undefined) {
				const autoCompleteResponse = await performGetRequest(`${autoCompleteUrl}${query || ''}`);
				users = mapAutoCompleteResponseToUsers(autoCompleteResponse, isBulkEdit);
			}
			return users.map((userData) => mapUserObjectToUserOption(userData, shouldShowEmailInOptions));
		};

		const handleSuggestions = (newSuggestions: UserOption[]): void => {
			if (!isDropdownVisible || !newSuggestions) {
				return;
			}

			// Prevent the selected value from appearing in the list of suggestions at initial load.
			const filteredSuggestions = wasFocused
				? newSuggestions.filter((suggestion) => !isCurrentValue(suggestion))
				: newSuggestions;

			setIsLoading(false);
			setSuggestions(filteredSuggestions);
			onDataLoaded(!query, filteredSuggestions.length);
		};

		const handleError = () => {
			setIsLoading(false);
			setSuggestions([]);
		};

		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		const requestSuggestions = (): Promise<UserOption[] | void> => {
			onDataRequest(!query);
			const { fetchSuggestions: fetchSuggestionsProps } = props;
			if (fetchSuggestionsProps) {
				return fetchSuggestionsProps(query, sessionId)
					.then((results) => {
						handleSuggestions(results);
					})
					.catch(handleError);
			}
			return fetchSuggestions()
				.then((results) => handleSuggestions(results))
				.catch(handleError);
		};
		return debounce(() => {
			requestSuggestions();
		}, debounceTime);
	}, [
		debounceTime,
		value,
		autoCompleteUrl,
		query,
		isBulkEdit,
		shouldShowEmailInOptions,
		isDropdownVisible,
		wasFocused,
		onDataLoaded,
		onDataRequest,
		props,
		sessionId,
	]);

	useEffect(() => {
		// Added extra !isDropdownVisible condition - avoid query when dropdown is closed
		if (query === null || !isDropdownVisible) return;
		requestSuggestionsDebounced();
		return () => {
			requestSuggestionsDebounced.cancel();
		};
	}, [requestSuggestionsDebounced, query, isDropdownVisible]);

	const onSelectedValueChangeMulti = (userOptionValue: UserOption[]): void => {
		if (userOptionValue && userOptionValue.length === 0) {
			onChange(null);
		} else {
			onChange(userOptionValue);
		}
	};

	const onSelectedValueChangeSingle = (userOptionValue: UserOption | null): void => {
		const valueId = userOptionValue && !Array.isArray(userOptionValue) && userOptionValue.id;
		if (valueId === USER_PICKER_EMPTY) {
			onChange(null);
		} else {
			onChange(userOptionValue);
		}
	};

	const onSuggestionSelect = (userOptionValue: Value, sId?: string): void => {
		setSessionId(sId || '');
		setWasFocused(false);
		setWasCleared(false);
		setIsLoading(false);
		setSuggestions([]);
		onSelection(sessionId);
		if (Array.isArray(userOptionValue)) {
			onSelectedValueChangeMulti(userOptionValue);
		} else {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onSelectedValueChangeSingle(userOptionValue as UserOption);
		}
	};

	const onBlur = (): void => {
		setIsDropdownVisible(false);
		setIsLoading(false);
		setWasFocused(false);
		setSuggestions([]);
		if (wasCleared) {
			onSuggestionSelect(getEmptyValue());
			return;
		}
		onCancel();
	};

	const onDropdownOpen = (sId?: string): void => {
		setSessionId(sId || '');
		setQueryString('');
		setIsDropdownVisible(true);
		setIsLoading(true);
		setWasFocused(true);
		setWasCleared(false);
	};

	const onQueryChange = (queryStr?: string, sId?: string): void => {
		setSessionId(sId || '');
		setQueryString(queryStr || '');
		setIsLoading(!!queryStr);
		setWasFocused(false);
		setIsDropdownVisible(!!queryStr);
	};

	const onClear = (): void => {
		setWasCleared(true);
		onSuggestionSelect(null);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const closeMenuOnScroll = (e: any): void => {
		if (onCloseMenuOnScroll && onCloseMenuOnScroll(e)) {
			setIsDropdownVisible(false);
		}
	};

	const isMatchingQuery = (suggestion: UserOption): boolean => {
		// when feature flag is false the predict should allow all
		const { displayName, name } = suggestion;
		return !isLoading || stringContains(displayName, query) || stringContains(name, query);
	};

	const getSuggestions = (): UserOption[] =>
		suggestions.filter((suggestion: UserOption) => isMatchingQuery(suggestion));

	const filteredSuggestions = getSuggestions();
	const pickerValue = value || getEmptyValue();

	const getNoOptionsMessage = (): string | undefined =>
		query === null || query === '' ? formatMessage(messages.emptyQueryMessage) : undefined;

	/*
	 * user picker default opens on focus, so most of the operations like loading, querying, dropdown open/close are modified during onFocus
	 * when openMenuOnClick is true then dropdown state managed internally as like select then have to use onOpen to modify any state like loading, querying
	 */
	const controlProps = openMenuOnClick
		? { openMenuOnClick, onOpen: onDropdownOpen }
		: { open: isDropdownVisible, onFocus: onDropdownOpen };

	return (
		<UserPicker
			value={pickerValue}
			options={filteredSuggestions}
			isMulti={isMulti}
			fieldId={fieldId}
			menuMinWidth={props.menuMinWidth}
			menuPortalTarget={portalElement}
			width={width}
			appearance={appearance}
			isClearable={isClearable}
			autoFocus={autoFocus}
			isDisabled={isDisabled}
			placeholder={placeholder}
			onBlur={onBlur}
			isLoading={isLoading && isDropdownVisible}
			onClear={onClear}
			onInputChange={onQueryChange}
			onChange={onSuggestionSelect}
			noBorder={noBorder}
			styles={styles}
			noOptionsMessage={getNoOptionsMessage}
			allowEmail={allowEmail}
			inputId={inputId}
			required={isRequired}
			ariaLabel={fieldProps['aria-label']}
			ariaLabelledBy={fieldProps['aria-labelledby']}
			closeMenuOnScroll={closeMenuOnScroll}
			menuPosition={menuPosition}
			{...controlProps}
		/>
	);
};

export default UserPickerWrapper;
