import { useState, useCallback, useRef } from 'react';
import { log } from '@atlassian/jira-common-util-logging';
import { performGetRequest } from '@atlassian/jira-fetch';
import type { Group } from '../../common/types';
import { appendTypePropertyToGroupOptions } from '../../common/utils';

type FetchGroupsResponse =
	| {
			groups: Group[];
	  }
	| undefined;

type FetchGroups = (query?: string) => Promise<Group[] | undefined>;

const useGroupsQuery = (
	autoCompleteUrl: string,
): {
	groups: Group[];
	error?: Error;
	loading: boolean;
	fetchGroups: FetchGroups;
} => {
	const [groups, setGroups] = useState<Group[]>([]);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	const currentQuery = useRef('');

	const fetchGroups = useCallback<FetchGroups>(
		async (query = '') => {
			if (['', null, undefined].includes(autoCompleteUrl)) {
				return Promise.resolve([]);
			}

			const groupsFetchUrl = `${autoCompleteUrl || ''}${query}`;

			setLoading(true);
			currentQuery.current = query;

			if (error) {
				setError(undefined);
			}

			try {
				const response: FetchGroupsResponse = await performGetRequest(groupsFetchUrl);
				let groupsWithType: Group[] = [];
				if (query === currentQuery.current) {
					if (response && response.groups) {
						groupsWithType = appendTypePropertyToGroupOptions(response.groups);
						setGroups(groupsWithType);
					} else {
						setGroups([]);
					}
				}
				return groupsWithType;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				if (query === currentQuery.current) {
					setError(err);
				}
				log.safeErrorWithoutCustomerData('issue.fields.grouppicker', 'Failed to fetch groups', err);
				return err;
			} finally {
				setLoading(false);
			}
		},
		[autoCompleteUrl, error],
	);

	return { groups, error, loading, fetchGroups };
};

export default useGroupsQuery;
