import messages from './messages';

const LEARN_MORE_TYPES = {
	IssueTypes: '#IssueTypes',
	PriorityLevels: '#PriorityLevels',
	ResolutionTypes: '#ResolutionTypes',
	SecurityLevels: '#SecurityLevels',
};

export const LEARN_MORE_MESSAGES = {
	[LEARN_MORE_TYPES.IssueTypes]: messages.fieldHelpIssueTypes,
	[LEARN_MORE_TYPES.PriorityLevels]: messages.fieldHelpPriorityLevels,
	[LEARN_MORE_TYPES.ResolutionTypes]: messages.fieldHelpResolutionDefinitions,
	[LEARN_MORE_TYPES.SecurityLevels]: messages.fieldHelpSecurityLevels,
};
